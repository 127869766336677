import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Dashboard from 'views/application/hostel/Dashboard';
import StudentDetails from 'views/application/hostel/students/StudentDetails';
import HostelReports from 'views/application/hostel/reports';

const HostelSetupAdd = Loadable(lazy(() => import('views/application/hostel/hostelsetup/index')));
const HostelSetupEdit = Loadable(lazy(() => import('views/application/hostel/hostelsetup/HostelEdit')));
const RoomCategoryAdd = Loadable(lazy(() => import('views/application/hostel/roomcategorysetup/index')));
const RoomSetupAdd = Loadable(lazy(() => import('views/application/hostel/roomsetup/index')));
const RoomSetupEdit = Loadable(lazy(() => import('views/application/hostel/roomsetup/RoomEdit')));
const HostelStudentAllocation = Loadable(lazy(() => import('views/application/hostel/studentallocation/index')));
const HostelFeeAllocation = Loadable(lazy(() => import('views/application/hostel/hostelfeeallocation/index')));
const ActiveQRCode = Loadable(lazy(() => import('views/application/hostel/hostelsetup/ActiveQRCode')));
const ActiveQRCodeDetails = Loadable(lazy(() => import('views/application/hostel/hostelsetup/ActiveQRCodeEdit')));
const HostelCheckOutList = Loadable(lazy(() => import('views/application/hostel/HostelCheckOutList')));
const HostelCheckOutStudentProfile = Loadable(lazy(() => import('views/application/hostel/CheckOutStudentProfile.')));
const HostelCheckOutApprovedStudentList = Loadable(lazy(() => import('views/application/hostel/CheckOutRequestApprovedList')));
const HostelDiscontinuedStudentList = Loadable(lazy(() => import('views/application/hostel/students/DiscontinueHostelList')));
const StudentHosteOverDueReport = Loadable(lazy(() => import('views/application/hostel/StudentHostelOverDueReport')));
const HostelAttendanceReport = Loadable(lazy(() => import('views/application/hostel/reports/HostelAttendanceReport')));
const HostelActiveCollection = Loadable(lazy(() => import('views/application/hostel/hostelActiveCollection/HostelActiveCollection')));
const HostelDailyActiveCollection = Loadable(
    lazy(() => import('views/application/hostel/hostelActiveCollection/DailyHostelCollectionReport'))
);

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const HostelRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/hostel/dashboard',
            element: <Dashboard />
        },
        {
            path: '/hostel/buildings/add',
            element: <HostelSetupAdd />
        },
        {
            path: '/hostel/active/collection',
            element: <HostelActiveCollection />
        },
        {
            path: '/hostel/daily/fee/collection',
            element: <HostelDailyActiveCollection />
        },
        {
            path: '/hostel/buildings/edit/:id',
            element: <HostelSetupEdit />
        },
        {
            path: '/room/category/add',
            element: <RoomCategoryAdd />
        },
        {
            path: '/hostel/rooms/add',
            element: <RoomSetupAdd />
        },
        {
            path: '/hostel/rooms/edit/:id',
            element: <RoomSetupEdit />
        },
        {
            path: '/hostel/student/allocation',
            element: <HostelStudentAllocation />
        },
        {
            path: '/hostel/student/allocation/:buildingId/:floorNumber',
            element: <HostelStudentAllocation />
        },
        {
            path: '/hostel/fee/allocation',
            element: <HostelFeeAllocation />
        },
        {
            path: '/hostel/reports',
            element: <HostelReports />
        },
        {
            path: '/hostel/student/details/:buildingId/:floorNumber/:room',
            element: <StudentDetails />
        },
        {
            path: '/hostel/student/details',
            element: <StudentDetails />
        },
        {
            path: '/active/QR/code/:id',
            element: <ActiveQRCode />
        },
        {
            path: '/active/QR/code/details/:id',
            element: <ActiveQRCodeDetails />
        },
        {
            path: '/hostel/checkout/list',
            element: <HostelCheckOutList />
        },
        {
            path: '/hostel/checkout/student/profile/:id',
            element: <HostelCheckOutStudentProfile />
        },
        {
            path: '/hostel/checkout/approved/student/list',
            element: <HostelCheckOutApprovedStudentList />
        },
        {
            path: '/hostel/discontinued/students/list',
            element: <HostelDiscontinuedStudentList />
        },
        {
            path: '/student/hostel/overdue/report',
            element: <StudentHosteOverDueReport />
        },
        {
            path: '/hostel/attendance/report',
            element: <HostelAttendanceReport />
        }
    ]
};
export default HostelRoutes;
