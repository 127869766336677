import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Department
const DepartmentReport = Loadable(lazy(() => import('views/application/report/profile/departmentReport/index')));
const ProgramReport = Loadable(lazy(() => import('views/application/report/program/index')));
const VisitorReport = Loadable(lazy(() => import('views/application/report/Visitor')));
const CollectionReport = Loadable(lazy(() => import('views/application/report/Collection')));
const AttendanceReport = Loadable(lazy(() => import('views/application/report/Attendance')));
const StudentReport = Loadable(lazy(() => import('views/application/report/Student')));
const StudentReportPHD = Loadable(lazy(() => import('views/application/report/Student')));
const CourseCollectionReport = Loadable(lazy(() => import('views/application/report/ProgramCollectionReport')));
const MultiProgramCollectionReport = Loadable(lazy(() => import('views/application/report/MultiProgramCollectionReport')));
const ProgramDueReport = Loadable(lazy(() => import('views/application/report/ProgramDueReport')));
const StudentCountReport = Loadable(lazy(() => import('views/application/report/SchoolStudentCount')));
const TotalStudentCountReport = Loadable(lazy(() => import('views/application/report/StudentCount')));
const DepartpentCountReport = Loadable(lazy(() => import('views/application/report/SchoolDepartmentCount')));
const ProgramCountReport = Loadable(lazy(() => import('views/application/report/SchoolProgramCount')));
const StudentProfile = Loadable(lazy(() => import('views/application/report/profile/StudentProfile')));
const CollectedFeesReport = Loadable(lazy(() => import('views/application/report/FeeReport/CollectedFees')));
const DailyCollectedFeesReport = Loadable(lazy(() => import('views/application/report/FeeReport/DailyCollection')));
const FeeDue = Loadable(lazy(() => import('views/application/report/FeeReport/FeeDue')));
const SessionDueReport = Loadable(lazy(() => import('views/application/report/FeeReport/SchoolWiseDue')));
const OngoingCollection = Loadable(lazy(() => import('views/application/report/FeeReport/ongoing-collection-report')));
const DepositeReport = Loadable(lazy(() => import('views/application/report/DepositeReprt')));
const StudentAttendenceReport = Loadable(lazy(() => import('views/application/StudentAttendance/Report/index')));
const ExpenseReport = Loadable(lazy(() => import('views/application/expense-report/index')));
const DailyCollectionCount = Loadable(lazy(() => import('views/application/report/FeeReport/DailyCollectionCount')));
const DailyStudentPayment = Loadable(lazy(() => import('views/application/report/FeeReport/DailyPaymentForStudent')));
const EmployeeDailyAttendance = Loadable(lazy(() => import('views/application/report/attendance/employee/EmployeeDailyAttendance')));
const EmployeeMonthlyAttendance = Loadable(lazy(() => import('views/application/report/attendance/employee/EmployeeMonthlyAttendance')));
const EmployeeAttendanceSummary = Loadable(lazy(() => import('views/application/report/attendance/employee/attendance-summary')));
// const StudentDailyAttendance = Loadable(lazy(() => import('views/application/report/attendance/student/StudentDailyAttendance')));
const CourseWiseDailyAttendance = Loadable(lazy(() => import('views/application/report/attendance/student/CourseWiseAttendance')));
const FacultyAttendanceReport = Loadable(lazy(() => import('views/application/report/attendance/faculty-attendance-report')));
const AttendanceCountReport = Loadable(
    lazy(() => import('views/application/report/attendance/faculty-attendance-report/AttendanceCountReport'))
);
const StudentMonthlyAttendance = Loadable(lazy(() => import('views/application/report/attendance/student/StudentMonthlyAttendance')));
const ProgramAttendanceDaily = Loadable(lazy(() => import('views/application/report/attendance/student/ProgramAttendanceDaily')));
const SubjectWiseDailyAttendance = Loadable(lazy(() => import('views/application/report/attendance/student/SubjectWiseDailyAttendance')));
const MonthWiseAttendanceReport = Loadable(lazy(() => import('views/application/report/attendance/student/MonthWiseAttendanceReport')));
const StudentAttendanceDailyList = Loadable(lazy(() => import('views/application/report/attendance/student/StudentAttendanceDailyList')));
const EmployeeLeaveReport = Loadable(lazy(() => import('views/application/report/EmployeeLeaveReport')));
const StudentAttendance = Loadable(lazy(() => import('views/application/report/StudentScholarship')));
const HostelMonthlyCollectionreport = Loadable(lazy(() => import('views/application/report/HostelMonthlyCollectionReport')));
const TransportCollectionreport = Loadable(lazy(() => import('views/application/report/profile/TransportCollectionReport')));

const EmployeePaySlip = Loadable(lazy(() => import('views/application/payrole/employeepayslip/EmployeePaySlip')));
const CollectionReportByBuilding = Loadable(lazy(() => import('views/application/report/CollectionReportByBuilding')));
const AdjustmentReport = Loadable(lazy(() => import('views/application/report/AdjustmentReport')));
const RefundableReport = Loadable(lazy(() => import('views/application/report/RefundableReport')));
const StudentAppDownload = Loadable(lazy(() => import('views/application/studentappdownload/StudentAppDownloadList')));
const OverAllLeaveReport = Loadable(lazy(() => import('views/application/report/leave_report/OverAllLeaveReport')));
const LeaveReportHistory = Loadable(lazy(() => import('views/application/report/leave_report/LeaveReportHistory')));
const StudentMarksheetDownload = Loadable(lazy(() => import('views/application/marksheetdownload/MarkSheetDownload')));
const DailyAdmissionFeeCollectionReport = Loadable(lazy(() => import('views/application/report/DailyAdmissionFeeCollection')));
const ClassTargetedReport = Loadable(lazy(() => import('views/application/report/ClassTopicReport/ReportFilter')));
const FacultyRatingReport = Loadable(lazy(() => import('views/application/feedback/report/FeedbackRatingReport')));
const SubstitutionReport = Loadable(lazy(() => import('views/application/report/TimeTableSubstitutionReport')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const ReportsRouts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/report/department',
            element: <DepartmentReport />
        },
        {
            path: '/class/progress/report',
            element: <ClassTargetedReport />
        },
        {
            path: '/report/program',
            element: <ProgramReport />
        },
        {
            path: '/report/visitor',
            element: <VisitorReport />
        },
        {
            path: '/report/collection',
            element: <CollectionReport />
        },
        {
            path: '/report/attendance',
            element: <AttendanceReport />
        },
        {
            path: '/report/Student',
            element: <StudentReport />
        },
        {
            path: '/report/Student/:type',
            element: <StudentReportPHD />
        },
        {
            path: '/report/student/count',
            element: <StudentCountReport />
        },
        {
            path: '/report/department/count/:id/:sid?',
            element: <DepartpentCountReport />
        },
        {
            path: '/report/program/count/:id/:sid?',
            element: <ProgramCountReport />
        },
        {
            path: '/report/student/count/:id/:sid?',
            element: <TotalStudentCountReport />
        },
        {
            path: '/student/profile/:id',
            element: <StudentProfile />
        },
        {
            path: '/collected/fees/report',
            element: <CollectedFeesReport />
        },
        {
            path: '/daily/collected/fees/report',
            element: <DailyCollectedFeesReport />
        },
        {
            path: '/due/fees/report',
            element: <FeeDue />
        },
        {
            path: '/report/StudentAttendenceReport',
            element: <StudentAttendenceReport />
        },
        {
            path: '/daily/collection/summary',
            element: <DailyCollectionCount />
        },
        {
            path: '/daily/student/payment',
            element: <DailyStudentPayment />
        },
        {
            path: '/employee/daily/attendance',
            element: <EmployeeDailyAttendance />
        },
        {
            path: '/employee/monthly/attendance',
            element: <EmployeeMonthlyAttendance />
        },
        {
            path: '/employee/attendance/summary',
            element: <EmployeeAttendanceSummary />
        },
        // {
        //     path:/coursewise/daily/attendance '/student/daily/attendance',
        //     element: <StudentDailyAttendance />
        // },
        {
            path: '/student/daily/attendance',
            element: <CourseWiseDailyAttendance />
        },
        {
            path: '/report/expense',
            element: <ExpenseReport />
        },
        {
            path: '/student/monthly/attendance',
            element: <StudentMonthlyAttendance />
        },
        {
            path: '/faculty-attendance-report',
            element: <FacultyAttendanceReport />
        },
        {
            path: '/attendance-report/:staffId/:type',
            element: <AttendanceCountReport />
        },
        {
            path: '/program/daily/attendance/:departmentId',
            element: <ProgramAttendanceDaily />
        },
        {
            path: '/student/daily/attendance/subjects/:courseId',
            element: <SubjectWiseDailyAttendance />
        },
        {
            path: '/month/wise/attendance/report',
            element: <MonthWiseAttendanceReport />
        },
        {
            path: '/student/daily/attendance/:courseId',
            element: <StudentAttendanceDailyList />
        },
        {
            path: '/program/collection/report',
            element: <CourseCollectionReport />
        },
        {
            path: '/program/due/report',
            element: <ProgramDueReport />
        },
        {
            path: '/session/due/report',
            element: <SessionDueReport />
        },
        {
            path: '/fee/active-collection',
            element: <OngoingCollection />
        },
        {
            path: '/deposite/report',
            element: <DepositeReport />
        },
        {
            path: '/multiProgram/collection/report',
            element: <MultiProgramCollectionReport />
        },
        {
            path: '/employee/leave/report',
            element: <EmployeeLeaveReport />
        },
        {
            path: '/student/scholarship',
            element: <StudentAttendance />
        },
        {
            path: '/hostel/monthly/collection/report',
            element: <HostelMonthlyCollectionreport />
        },
        {
            path: '/transport/collection/report',
            element: <TransportCollectionreport />
        },
        {
            path: '/employee/pay/slip',
            element: <EmployeePaySlip />
        },
        {
            path: '/collection/report/by/building',
            element: <CollectionReportByBuilding />
        },
        {
            path: '/adjustment/report',
            element: <AdjustmentReport />
        },
        {
            path: '/refundable/report',
            element: <RefundableReport />
        },
        {
            path: '/student/app/download/report',
            element: <StudentAppDownload />
        },
        {
            path: '/student/marksheet/download',
            element: <StudentMarksheetDownload />
        },
        {
            path: '/overall/leave/report',
            element: <OverAllLeaveReport />
        },
        {
            path: '/leave/report/history',
            element: <LeaveReportHistory />
        },
        {
            path: '/daily/admission/collection/report',
            element: <DailyAdmissionFeeCollectionReport />
        },
        {
            path: '/faculty/rating/report',
            element: <FacultyRatingReport />
        },
        {
            path: '/timetable/substitution/report',
            element: <SubstitutionReport />
        }
    ]
};

export default ReportsRouts;
