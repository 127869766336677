/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
// import ErrorAlert from 'reusable-components/alert/Alert';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children, roles }) => {
    const menuPermissions = useSelector((state) => state.menuPermissions);
    const role = localStorage.getItem('role');
    const userType = localStorage.getItem('userTypes');
    const { pathname } = useLocation();
    const auth = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const currentUserRole = auth?.user?.role[0]?.roleName;
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);
    // useEffect(() => {
    //     if (role === 'ROLE_COLLEGE_SUB_ADMIN' && userType !== 'CHANCELLOR' && userType !== 'COE') {
    //         if (menuPermissions?.isAllowed) {
    //             navigate(`${pathname}`);
    //         }
    //         if (menuPermissions?.isAllowed === false || menuPermissions.isAllowed === null) {
    //             navigate('/pages/error');
    //         }
    //     }
    // }, [pathname, menuPermissions?.isAllowed, navigate]);

    // useEffect(() => {
    //     if (!roles?.includes(currentUserRole)) {
    //         navigate('/pages/error');
    //     }
    // }, [navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default AuthGuard;
