/* eslint-disable no-nested-ternary */

import { FormattedMessage } from 'react-intl';

// assets
import { IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';

import axios from 'axios';
// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

// const children = [];

const menus = {
    id: 'application',
    // title: <FormattedMessage id="application" />,
    icon: icons.IconApps,
    type: 'group',
    children: []
};
const intituteId = localStorage.getItem('instituteId');
const menuInit = async () => {
    // const response = await axios.get('http://192.168.68.114:5002/api/v1/menus/user/menu', {
    const response = await axios.get('https://api.ustmentrar.com/api/v1/menus/user/menu', {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
            'Content-Type': 'application/json'
        }
    });
    const menuData = [];
    // eslint-disable-next-line array-callback-return
    response.data.data.map(async (item, key) => {
        const temp = {
            id: item.menuName,
            title: <FormattedMessage id={item.menuName} />,
            type: item.submenu.length === 0 ? 'mainMenu' : 'collapse',
            target: false,
            breadcrumbs: false,
            icon: item.menuIcon,
            url:
                item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'HOD'
                    ? '/hod/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Accountant'
                    ? '/accountant/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Admission_Handler'
                    ? '/admission/handler/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Dean'
                    ? '/dean/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'DUC'
                    ? '/duc/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Registrar'
                    ? '/registrar/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'COE'
                    ? '/management/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'PLACEMENT_IN_CHARGE'
                    ? '/placement/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'CHANCELLOR'
                    ? '/management/dashboard'
                    : item.url,
            children: []
        };
        const submenuTemp = [];
        if (item.submenu.length > 0) {
            item.submenu
                .filter((item) => item.visible)
                .map(async (value) => {
                    const tempsubmenu = {
                        id: value.menuName,
                        title: <FormattedMessage id={value.menuName} />,
                        type: 'item',
                        target: false,
                        url: value.url
                    };
                    submenuTemp.push(tempsubmenu);
                });
            temp.children = submenuTemp;
        }
        menuData.push(temp);
    });
    menus.children = menuData;
};

const staticMenu = () => {
    const staticMenuData = [
        {
            id: 'Institute',
            title: <FormattedMessage id="Institute" />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/institute/list',
            children: []
        },
        {
            id: 'Institute',
            title: <FormattedMessage id="Dashboard Module" />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/dashboard/module',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const staticMenuStudent = () => {
    const staticMenuData = [
        {
            id: 'Registration',
            title: <FormattedMessage id="Registration" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/registration',
            children: []
        }
    ];
    menus.children = staticMenuData;
};

// student Menu

const StudentMenu = () => {
    const staticMenuData = [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/dashboard',
            children: []
        },
        {
            id: 'Assignments',
            title: <FormattedMessage id="Assignments" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/assignments',
            children: []
        },
        {
            id: 'Resources',
            title: <FormattedMessage id="Resources" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/resources',
            children: []
        },
        {
            id: 'Elective',
            title: <FormattedMessage id="Elective Selection" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/subject/electives',
            children: []
        },
        {
            id: 'Placement',
            title: <FormattedMessage id="Placement" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/placement',
            children: []
        }
    ];
    menus.children = staticMenuData;
};

// for institute Admin

const staticInstitute = () => {
    const staticMenuData = [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/c59a076b-d4e1-4291-86ec-d71c2439d018',
            // url: '/institute/dashboard',
            breadcrumbs: false,
            children: [
                {
                    id: 'Institute',
                    title: <FormattedMessage id="Institute" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/institute/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Hostel',
                    title: <FormattedMessage id="Hostel" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/hostel/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Library',
                    title: <FormattedMessage id="Library" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/library/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Payment Collection',
                    title: <FormattedMessage id="Payment Collection" />,
                    type: 'item',
                    url: '/payments/dashboard',
                    target: false
                },
                // {
                //     id: 'FeeDueDashboard',
                //     title: <FormattedMessage id="Fee Due Dashboard" />,
                //     type: 'item',
                //     url: '/fee/flow/report',
                //     target: false
                // },
                {
                    id: 'ExamDashboard',
                    title: <FormattedMessage id="Exam Dashboard" />,
                    type: 'item',
                    url: '/exam/dashboard',
                    target: false
                },
                {
                    id: 'My Calendar',
                    title: <FormattedMessage id="My Calendar" />,
                    type: 'item',
                    url: '/calender/event',
                    target: false
                }
            ]
        },
        {
            id: 'Schools',
            title: <FormattedMessage id={intituteId === '64f6beb1a7bf157d349d821f' ? 'Colleges' : 'Schools'} />,
            type: 'mainMenu',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/55542155-8502-4aa2-b4f0-cdaedadca94f',
            url: '/college/list',
            breadcrumbs: false,
            children: []
        },
        {
            id: 'Admission',
            title: <FormattedMessage id="Admission" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/456740ed-6d32-45fc-bbac-afd56de816ae',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Registration',
                    title: <FormattedMessage id="Registration List" />,
                    type: 'item',
                    url: '/student/registration/list',
                    target: false
                },
                {
                    id: 'Admission',
                    title: <FormattedMessage id="Admission List" />,
                    type: 'item',
                    url: '/student/admission/list',
                    target: false
                },
                {
                    id: 'Offline Registration',
                    title: <FormattedMessage id="Offline Registration" />,
                    type: 'item',
                    url: '/offline/registration',
                    target: false
                },
                // {
                //     id: 'Offline Admission',
                //     title: <FormattedMessage id="Offline Admission" />,
                //     type: 'item',
                //     url: '/offline/student/profile',
                //     target: false
                // },
                // {
                //     id: 'Internal Admission',
                //     title: <FormattedMessage id="Internal Admission" />,
                //     type: 'item',
                //     url: '/internal/admission',
                //     target: false
                // },
                {
                    id: 'EntranceExam',
                    title: <FormattedMessage id="Entrance Exam" />,
                    type: 'item',
                    url: '/admission/entranceexam',
                    target: false
                },
                {
                    id: 'Distance Education',
                    title: <FormattedMessage id="Distance Education" />,
                    type: 'item',
                    url: '/distance/education/admission',
                    target: false
                },
                {
                    id: 'AdmissionCancel',
                    title: <FormattedMessage id="Admission Cancel List" />,
                    type: 'item',
                    url: '/discontinue/student/list',
                    children: []
                },
                {
                    id: 'PendingAdmissionCancelList',
                    title: <FormattedMessage id="Pending Admission Cancel List" />,
                    type: 'item',
                    url: '/admission/cancel/approval/list',
                    children: []
                },
                {
                    id: 'Change Program',
                    title: <FormattedMessage id="Change Program" />,
                    type: 'item',
                    url: '/change/program',
                    children: []
                },
                {
                    id: 'Change Degree',
                    title: <FormattedMessage id="Change Degree" />,
                    type: 'item',
                    url: '/change/degree',
                    children: []
                }
            ]
        },
        {
            id: 'Student',
            title: <FormattedMessage id="Student" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/ef7dc3cb-c921-4453-aaa6-0a5c211d5559',
            children: [
                {
                    id: 'Student Promotion',
                    title: <FormattedMessage id="Student Promotion" />,
                    type: 'item',
                    url: '/student/promotion',
                    children: []
                },
                {
                    id: 'AssignElectiveCourse',
                    title: <FormattedMessage id="Assign Elective Course" />,
                    type: 'item',
                    url: '/subject/allow/electives',
                    children: []
                },
                {
                    id: 'Apply For Scholarship',
                    title: <FormattedMessage id="Apply For Scholarship" />,
                    type: 'item',
                    url: '/payment/plan',
                    children: []
                },
                {
                    id: 'Active Students',
                    title: <FormattedMessage id="Active Students" />,
                    type: 'item',
                    url: '/student/accounts',
                    children: []
                },
                {
                    id: 'StudentScholarship',
                    title: <FormattedMessage id="Students Scholarship" />,
                    type: 'item',
                    url: '/student/scholarship',
                    children: []
                },
                {
                    id: 'StudentAppDownload',
                    title: <FormattedMessage id="Student App Download" />,
                    type: 'item',
                    url: '/student/app/download/report',
                    children: []
                },
                {
                    id: 'Student Marksheet Download',
                    title: <FormattedMessage id="Student Marksheet Download" />,
                    type: 'item',
                    url: '/student/marksheet/download',
                    children: []
                },
                {
                    id: 'Student Face Registered',
                    title: <FormattedMessage id="Student Face Registered" />,
                    type: 'item',
                    url: '/student/face/register',
                    children: []
                }
            ]
        },
        {
            id: 'Account',
            title: <FormattedMessage id="Account" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b484bd36-07cf-4839-a624-0594c71ea452',
            // url: '/college/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Accounting Division',
                    title: <FormattedMessage id="Accounting Division" />,
                    type: 'item',
                    url: '/acounting/add',
                    target: false
                },
                {
                    id: 'Receipt Book',
                    title: <FormattedMessage id="Receipt Book" />,
                    type: 'item',
                    url: '/receipt/add',
                    target: false
                },
                {
                    id: 'Fee Code',
                    title: <FormattedMessage id="Fee Code" />,
                    type: 'item',
                    url: `/fee`,
                    target: false
                },
                {
                    id: 'Fee Mapping',
                    title: <FormattedMessage id="Fee Mapping" />,
                    type: 'item',
                    url: `/feeMapping`,
                    target: false
                },
                {
                    id: 'Fee Fine',
                    title: <FormattedMessage id="Fee Fine" />,
                    type: 'item',
                    url: `/feeFine`,
                    target: false
                }
            ]
        },
        {
            id: 'Fee',
            title: <FormattedMessage id="Fee" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/3da550a0-28e3-4717-8a2e-db11c3b4e5a0',
            breadcrumbs: false,
            children: [
                {
                    id: 'AdmissionFeesCollection',
                    title: <FormattedMessage id="Admission Fees Collection" />,
                    type: 'item',
                    url: '/offline/fee/payment',
                    target: false
                },
                {
                    id: 'FeeCollectionEntry',
                    title: <FormattedMessage id="Fee Collection Entry" />,
                    type: 'item',
                    url: '/student/offline/fee/payment',
                    target: false
                },
                {
                    id: 'Prospectus Fees Collection',
                    title: <FormattedMessage id="Prospectus Fees Collection" />,
                    type: 'item',
                    url: '/offline/registration/fees/payment',
                    target: false
                },
                {
                    id: 'Collected Fee',
                    title: <FormattedMessage id="Collected Fee" />,
                    type: 'item',
                    url: '/collected/fees/report',
                    target: false
                },
                // {
                //     id: 'Batch Due',
                //     title: <FormattedMessage id="Batch Due" />,
                //     type: 'item',
                //     url: '/due/fees/report',
                //     target: false
                // },
                {
                    id: 'Daily Fee Collection',
                    title: <FormattedMessage id="Daily Fee Collection" />,
                    type: 'item',
                    url: '/daily/collected/fees/report',
                    target: false
                },
                {
                    id: 'Daily Fee Collection Summary',
                    title: <FormattedMessage id="Daily Fee Collection Summary" />,
                    type: 'item',
                    url: '/daily/collection/summary',
                    target: false
                },
                {
                    id: 'Session Due',
                    title: <FormattedMessage id="Session Due" />,
                    type: 'item',
                    url: '/session/due/report',
                    target: false
                },
                {
                    id: 'Fee Refund / Adjust',
                    title: <FormattedMessage id="Fee Refund / Adjust" />,
                    type: 'item',
                    url: '/fee/refund',
                    target: false
                },
                {
                    id: 'Edit Receipt',
                    title: <FormattedMessage id="Edit Receipt" />,
                    type: 'item',
                    url: '/payment/receipt',
                    target: false
                },
                {
                    id: 'StudentFeeProfile',
                    title: <FormattedMessage id="Student Fee Profile" />,
                    type: 'item',
                    url: '/student/fees/profile',
                    target: false
                },
                {
                    id: 'BacklogImprovementFeeSetup',
                    title: <FormattedMessage id="Backlog/Improvement Fee Setup" />,
                    type: 'item',
                    url: '/backlog/improvement/list',
                    target: false
                }
                // {
                //     id: 'FeeDefaulterHallTicket',
                //     title: <FormattedMessage id="Fee Defaulter Hall Ticket" />,
                //     type: 'item',
                //     url: '/fee/defaulter/hall/ticket',
                //     target: false
                // },
                // {
                //     id: 'HallTicketRequestList',
                //     title: <FormattedMessage id="Hall Ticket Request List" />,
                //     type: 'item',
                //     url: '/hall/ticket/approval/request/list',
                //     target: false
                // }
            ]
        },

        {
            id: 'Employee',
            title: <FormattedMessage id="Employee" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/326ad56a-07b4-4fad-a462-13c8b528cfd2',
            url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Employee Registration',
                    title: <FormattedMessage id="Employee Registration" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/staff/registration',
                    target: false
                },
                {
                    id: 'FacultySubjectAllocation',
                    title: <FormattedMessage id="Faculty Subject Allocation" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/staff/subject/allocation',
                    target: false
                },
                {
                    id: 'Employee Mail Activation',
                    title: <FormattedMessage id="Employee Mail Activation" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/employee/accounts',
                    target: false
                },
                {
                    id: 'Assign Group',
                    title: <FormattedMessage id="Assign Group" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/assign/group',
                    target: false
                },
                {
                    id: 'DownloadFacultyTimeTable',
                    title: <FormattedMessage id="Download Faculty TimeTable" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/staff/timetable/download',
                    target: false
                },
                {
                    id: 'Staff Appraisal',
                    title: <FormattedMessage id="Staff Appraisal" />,
                    type: 'item',
                    url: '/appraisal/staff',
                    target: false
                }
            ]
        },
        {
            id: 'Attendance',
            title: <FormattedMessage id="Attendance" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/fc4334e6-a1e8-4036-8842-38942d71a7c9',
            breadcrumbs: false,
            children: [
                {
                    id: 'StudentAttendance',
                    title: <FormattedMessage id="Student Attendance" />,
                    type: 'item',
                    url: '/student/attendance',
                    target: false
                },
                {
                    id: 'Student Daily Attendance',
                    title: <FormattedMessage id="Student Daily Attendance" />,
                    type: 'item',
                    url: '/student/daily/attendance',
                    target: false
                },
                {
                    id: 'Student Monthly Attendance',
                    title: <FormattedMessage id="Student Monthly Attendance" />,
                    type: 'item',
                    url: '/student/monthly/attendance',
                    target: false
                },
                {
                    id: 'EmployeeAttendance',
                    title: <FormattedMessage id="Employee Attendance" />,
                    type: 'item',
                    url: '/staff/attendance',
                    target: false
                },
                {
                    id: 'Employee Daily Attendance',
                    title: <FormattedMessage id="Employee Daily Attendance" />,
                    type: 'item',
                    url: '/employee/daily/attendance',
                    target: false
                },
                {
                    id: 'Employee Attendance (Subject-Wise)',
                    title: <FormattedMessage id="Employee Attendance (Subject-Wise)" />,
                    type: 'item',
                    url: '/faculty-attendance',
                    target: false
                },
                {
                    id: 'Employee Monthly Attendance',
                    title: <FormattedMessage id="Employee Monthly Attendance" />,
                    type: 'item',
                    url: '/employee/monthly/attendance',
                    target: false
                },
                {
                    id: 'Employee Attendance Summary',
                    title: <FormattedMessage id="Employee Attendance Summary" />,
                    type: 'item',
                    url: '/employee/attendance/summary',
                    target: false
                },
                {
                    id: 'Student Monthly Subject Attendance',
                    title: <FormattedMessage id="Student Monthly Subject Attendance" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/month/wise/attendance/report',
                    breadcrumbs: false
                }
                // {
                //     id: 'Student Attendance',
                //     title: <FormattedMessage id="Student Attendance" />,
                //     type: 'item',
                //     // icon: icons.IconUserCheck,
                //     url: '/report/StudentAttendenceReport',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'Time Table',
            title: <FormattedMessage id="Time Table" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/4215da4f-32d1-4c01-a9d9-d9c6a997dcdb',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'TimeTableDashboard',
                    title: <FormattedMessage id="Time Table Dashboard" />,
                    type: 'item',
                    url: '/timetable/dashboard',
                    target: false
                },
                {
                    id: 'TimeTableList',
                    title: <FormattedMessage id="Time Table" />,
                    type: 'item',
                    url: '/timetable',
                    target: false
                },
                // {
                //     id: 'GenerateTimeTable',
                //     title: <FormattedMessage id="Generate Time Table" />,
                //     type: 'item',
                //     url: '/generateTimeTable',
                //     target: false
                // },
                {
                    id: 'TimeSlot',
                    title: <FormattedMessage id="Time Slot" />,
                    type: 'item',
                    url: '/timeSlot',
                    children: []
                },
                {
                    id: 'DownloadTimeTable',
                    title: <FormattedMessage id="Download Time Table" />,
                    type: 'item',
                    url: '/download/timetable',
                    target: false
                }
            ]
        },
        {
            id: 'Lesson Plan',
            title: <FormattedMessage id="Lesson Plan" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b4a2661d-92e9-4b56-ae27-db1d0b756c31',
            children: [
                {
                    id: 'Lesson Plan',
                    title: <FormattedMessage id="Lesson Plan" />,
                    type: 'item',
                    url: '/institute/lessionplan/add',
                    children: []
                },
                {
                    id: 'DownloadLessonPlanPdf',
                    title: <FormattedMessage id="Download Lesson Plan Pdf" />,
                    type: 'item',
                    url: '/institute/lessionplan/pdf',
                    children: []
                },
                {
                    id: 'Class Porgess Report',
                    title: <FormattedMessage id="Class Progess Report" />,
                    type: 'item',
                    url: '/class/progress/report',
                    children: []
                },
                {
                    id: 'Class Allotment Report',
                    title: <FormattedMessage id="Class Allotment Report" />,
                    type: 'item',
                    url: '/class/allotment/report',
                    children: []
                }
            ]
        },
        {
            id: 'Assignments',
            title: <FormattedMessage id="Assignments" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: '/assets/images/icons/AssignmentMenuIcon.svg',
            children: [
                {
                    id: 'Assignments',
                    title: <FormattedMessage id="Assignments" />,
                    type: 'item',
                    url: '/assignment',
                    target: false
                }
            ]
        },
        {
            id: 'Announcements',
            title: <FormattedMessage id="Announcements" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/73ec3091-7d43-4639-ac1f-fb8737869787',
            children: [
                {
                    id: 'Announcement',
                    title: <FormattedMessage id="Announcement" />,
                    type: 'item',
                    url: '/announcements',
                    target: false
                }
            ]
        },

        {
            id: 'Exam Report',
            title: <FormattedMessage id="Exam Report" />,
            type: 'collapse',
            icon: '/assets/images/icons/Report.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'ExamTimeTable',
                    title: <FormattedMessage id="Exam TimeTable" />,
                    type: 'item',
                    url: '/exam/timetable',
                    target: false
                },
                {
                    id: 'ExamTabulationSheet',
                    title: <FormattedMessage id="Exam Tabulation Sheet" />,
                    type: 'item',
                    url: '/exam/marksheet',
                    target: false
                },
                {
                    id: 'BulkSemesterGradeSheets',
                    title: <FormattedMessage id="Bulk Semester Grade Sheets" />,
                    type: 'item',
                    url: '/exam/semestergradesheets',
                    target: false
                },
                {
                    id: 'StudentGradeSheet',
                    title: <FormattedMessage id="Student Grade Sheet" />,
                    type: 'item',
                    url: '/student/marksheet/download',
                    children: []
                },
                {
                    id: 'BacklogStudent',
                    title: <FormattedMessage id="Backlog Student" />,
                    type: 'item',
                    url: '/backlog/students',
                    target: false
                },
                {
                    id: 'OverallAcademicReport',
                    title: <FormattedMessage id="Overall Academic Report" />,
                    type: 'item',
                    url: '/overall/academic/report',
                    target: false
                },
                {
                    id: 'Academic Performance',
                    title: <FormattedMessage id="Academic Performance" />,
                    type: 'item',
                    url: '/exam/performance',
                    target: false
                }
            ]
        },
        {
            id: 'Marks Entry',
            title: <FormattedMessage id="Marks Entry" />,
            type: 'collapse',
            icon: '/assets/images/icons/Exam_Entry.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                // {
                //     id: 'Sessional Marks Entry',
                //     title: <FormattedMessage id="Sessional Marks Entry" />,
                //     type: 'item',
                //     // url: '/exam/marks/internal-assessment',
                //     url: '/sessional/marks/entry',
                //     target: false
                // },
                {
                    id: 'InternalAssessmentMarksEntry',
                    title: <FormattedMessage id="Internal Assessment Marks Entry" />,
                    type: 'item',
                    // url: '/exam/marks/internal-assessment',
                    url: '/exam/internal/marks/entry',
                    target: false
                },
                {
                    id: 'SemesterMarksEntry',
                    title: <FormattedMessage id="Semester Marks Entry" />,
                    type: 'item',
                    url: '/exam/marks/add',
                    target: false
                },
                {
                    id: 'ExamAttendance',
                    title: <FormattedMessage id="Exam Attendance" />,
                    type: 'item',
                    url: '/exam/attendence',
                    target: false
                }
            ]
        },
        {
            id: 'Library',
            title: <FormattedMessage id="Library" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b0510a3a-7a66-45c1-8378-11c20956f1d7',
            children: [
                {
                    id: 'LibraryCategory',
                    title: <FormattedMessage id="Library Category" />,
                    type: 'item',
                    url: '/library/Category',
                    target: false
                },
                {
                    id: 'LibraryCategoryType',
                    title: <FormattedMessage id="Library Category Type" />,
                    type: 'item',
                    url: '/library/Categorytype',
                    target: false
                },
                {
                    id: 'AddBook',
                    title: <FormattedMessage id="Add Book" />,
                    type: 'item',
                    url: '/library/AddBook',
                    target: false
                },
                {
                    id: 'Issue/Return Book',
                    title: <FormattedMessage id="Issue/Return Book" />,
                    type: 'item',
                    url: '/library/issuebook',
                    target: false
                },

                {
                    id: 'Library Settings',
                    title: <FormattedMessage id="Library Settings" />,
                    type: 'item',
                    url: '/library/Setting',
                    target: false
                }
            ]
        },
        {
            id: 'Hostel',
            title: <FormattedMessage id="Hostel" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/c27020f6-e843-46c6-8752-96ccd6c07157',
            children: [
                {
                    id: 'Hostel Building',
                    title: <FormattedMessage id="Hostel Building" />,
                    type: 'item',
                    url: '/hostel/buildings/add',
                    children: []
                },
                {
                    id: 'Rooms',
                    title: <FormattedMessage id="Rooms" />,
                    type: 'item',
                    url: '/hostel/rooms/add',
                    children: []
                },
                {
                    id: 'Rooms Category',
                    title: <FormattedMessage id="Rooms Category" />,
                    type: 'item',
                    url: '/room/category/add',
                    children: []
                },
                {
                    id: 'StudentAllocation',
                    title: <FormattedMessage id="Student Allocation" />,
                    type: 'item',
                    url: '/hostel/student/allocation',
                    target: false
                },
                {
                    id: 'HostelFeeAllocation',
                    title: <FormattedMessage id="Hostel Fee Allocation" />,
                    type: 'item',
                    url: '/hostel/fee/allocation',
                    target: false
                },
                {
                    id: 'Viewstudentlist ',
                    title: <FormattedMessage id="View Student List" />,
                    type: 'item',
                    url: '/hostel/student/details',
                    target: false
                },
                {
                    id: 'CheckoutRequest',
                    title: <FormattedMessage id="Checkout Request" />,
                    type: 'item',
                    url: '/hostel/checkout/list',
                    target: false
                },
                {
                    id: 'CheckoutRequestApproved',
                    title: <FormattedMessage id="Checkout Approved List" />,
                    type: 'item',
                    url: '/hostel/checkout/approved/student/list',
                    target: false
                },
                {
                    id: 'HostelReports',
                    title: <FormattedMessage id="Hostel Occupancy Reports" />,
                    type: 'item',
                    url: '/hostel/reports',
                    target: false
                },
                {
                    id: 'HostelAttendanceReport',
                    title: <FormattedMessage id="Hostel Attendance Report" />,
                    type: 'item',
                    url: '/hostel/attendance/report',
                    target: false
                },
                {
                    id: 'DiscontinuedStudents',
                    title: <FormattedMessage id="Discontinued Students" />,
                    type: 'item',
                    url: '/hostel/discontinued/students/list',
                    target: false
                },
                {
                    id: 'HostelBusCollectionAndDue',
                    title: <FormattedMessage id="Hostel Collection And Due" />,
                    type: 'item',
                    url: '/hostel/monthly/collection/report',
                    target: false
                },
                {
                    id: 'CollectionDueByHostelBuilding',
                    title: <FormattedMessage id="Collection / Due By Hostel Building" />,
                    type: 'item',
                    url: '/collection/report/by/building',
                    target: false
                },
                {
                    id: 'StudentHostelOverDueReport',
                    title: <FormattedMessage id="Student Hostel Overdue Report" />,
                    type: 'item',
                    url: '/student/hostel/overdue/report',
                    target: false
                }
            ]
        },
        {
            id: 'Transport',
            title: <FormattedMessage id="Transport" />,
            type: 'collapse',
            icon: '/assets/images/icons/Transport.svg',
            breadcrumbs: false,
            children: [
                {
                    id: 'BusStoppage',
                    title: <FormattedMessage id="Bus Stoppage" />,
                    type: 'item',
                    url: '/bus/stoppage',
                    target: false
                },
                {
                    id: 'StudentAllocation',
                    title: <FormattedMessage id="Student Allocation" />,
                    type: 'item',
                    url: '/transport/allocate/student',
                    target: false
                },
                {
                    id: 'TransportOccupancyReport',
                    title: <FormattedMessage id="Transport Occupancy Report" />,
                    type: 'item',
                    url: '/transport/occupancy/report',
                    target: false
                },
                {
                    id: 'DiscontinueStudentList',
                    title: <FormattedMessage id="Discontinue Student List" />,
                    type: 'item',
                    url: '/transport/discontinue/list',
                    target: false
                },
                {
                    id: 'TransportBusCollectionAndDue',
                    title: <FormattedMessage id="Transport Collection And Due" />,
                    type: 'item',
                    url: '/transport/collection/report',
                    target: false
                }
            ]
        },
        {
            id: 'Leave',
            title: <FormattedMessage id="Leave" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7b3b8d53-2647-4570-8ee7-1425a10df239',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Apply Leave',
                    title: <FormattedMessage id="Apply Leave" />,
                    type: 'item',
                    url: '/teacher/leaves',
                    target: false
                },
                {
                    id: 'Leave Approve',
                    title: <FormattedMessage id="Leave Approve" />,
                    type: 'item',
                    url: '/teacher/leaves/approve',
                    target: false
                },
                {
                    id: 'UpdateLOP',
                    title: <FormattedMessage id="Update LOP" />,
                    type: 'item',
                    url: '/update/lop',
                    target: false
                },
                {
                    id: 'LeaveReport',
                    title: <FormattedMessage id="Leave Report" />,
                    type: 'item',
                    url: '/leave/report',
                    target: false
                },
                {
                    id: 'EmployeeLeave',
                    title: <FormattedMessage id="Employee Leave" />,
                    type: 'item',
                    url: '/employee/leave/report',
                    target: false
                },
                {
                    id: 'OverAllLeaveReport',
                    title: <FormattedMessage id="Over All Leave Report" />,
                    type: 'item',
                    url: '/overall/leave/report',
                    target: false
                }
            ]
        },
        {
            id: 'PayRoll',
            title: <FormattedMessage id="Pay Roll" />,
            type: 'collapse',
            icon: '/assets/images/icons/Payroll.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'PayRollComponentList',
                    title: <FormattedMessage id="Pay Roll Component List" />,
                    type: 'item',
                    url: '/payrole/master',
                    children: []
                },
                {
                    id: 'PayRollAllocation',
                    title: <FormattedMessage id="Pay Roll Allocation" />,
                    type: 'item',
                    url: '/payrole-allocation',
                    children: []
                },
                {
                    id: 'GenerateBulkPaySlip',
                    title: <FormattedMessage id="Generate Bulk Pay Slip" />,
                    type: 'item',
                    url: '/generate/bulk/pay/slip',
                    children: []
                },
                {
                    id: 'PayslipSummary',
                    title: <FormattedMessage id="Payslip Summary" />,
                    type: 'item',
                    url: '/payslip/summary',
                    children: []
                },
                {
                    id: 'PayslipSummary',
                    title: <FormattedMessage id="Monthly Bank Statement" />,
                    type: 'item',
                    url: '/monthly/bank/statement',
                    children: []
                },
                {
                    id: 'EmployeePaySlip',
                    title: <FormattedMessage id="Employee Pay Slip" />,
                    type: 'item',
                    url: '/employee/pay/slip',
                    children: []
                }
            ]
        },
        {
            id: 'Placement',
            title: <FormattedMessage id="Placement" />,
            type: 'collapse',
            icon: '/assets/images/icons/Placement.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Dashboard',
                    title: <FormattedMessage id="Dashboard" />,
                    type: 'item',
                    url: '/placement/dashboard',
                    target: false
                },
                {
                    id: 'Companies',
                    title: <FormattedMessage id="Companies" />,
                    type: 'item',
                    url: '/companies/list',
                    target: false
                },
                {
                    id: 'Jobs',
                    title: <FormattedMessage id="Jobs" />,
                    type: 'item',
                    url: '/create/jobs/openings',
                    target: false
                },
                {
                    id: 'Job Tracker',
                    title: <FormattedMessage id="Job Tracker" />,
                    type: 'item',
                    url: '/job/tracker',
                    target: false
                },
                {
                    id: 'Student Report',
                    title: <FormattedMessage id="Student Report" />,
                    type: 'item',
                    url: '/student/report',
                    target: false
                },
                {
                    id: 'Qualification',
                    title: <FormattedMessage id="Qualification" />,
                    type: 'item',
                    url: '/placement/qualification',
                    target: false
                },
                {
                    id: 'Placement Setup',
                    title: <FormattedMessage id="Placement Setup" />,
                    type: 'item',
                    url: '/placementweeks',
                    target: false
                },
                {
                    id: 'Job Posting Approval',
                    title: <FormattedMessage id="Job Posting Approval" />,
                    type: 'item',
                    url: '/comapny/job/list',
                    target: false
                },
                {
                    id: 'Prospective Companies',
                    title: <FormattedMessage id="Prospective Companies" />,
                    type: 'item',
                    url: '/outreach/companies',
                    target: false
                }
            ]
        },
        {
            id: 'Alumni',
            title: <FormattedMessage id="Alumni" />,
            type: 'collapse',
            icon: '/assets/images/icons/Alumni.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Alumni Report',
                    title: <FormattedMessage id="Alumni Report" />,
                    type: 'item',
                    url: '/student/alumni',
                    target: false
                }
            ]
        },
        {
            id: 'Careers',
            title: <FormattedMessage id="Careers" />,
            type: 'collapse',
            icon: '/assets/images/icons/HR.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Hiring Process',
                    title: <FormattedMessage id="Hiring Process" />,
                    type: 'item',
                    url: '/hiring-process',
                    target: false
                },
                {
                    id: 'Skills',
                    title: <FormattedMessage id="Skills" />,
                    type: 'item',
                    url: '/skills',
                    target: false
                },
                {
                    id: 'Job Post Request',
                    title: <FormattedMessage id="Job Post Request" />,
                    type: 'item',
                    url: '/job-post-request',
                    target: false
                },
                {
                    id: 'Create Job Post',
                    title: <FormattedMessage id="Create Job Post" />,
                    type: 'item',
                    url: '/job-post-create',
                    target: false
                },
                {
                    id: 'Application List',
                    title: <FormattedMessage id="Application List" />,
                    type: 'item',
                    url: '/job/application/list',
                    target: false
                },
                {
                    id: 'Offered Candidates',
                    title: <FormattedMessage id="Offered Candidates" />,
                    type: 'item',
                    url: '/package/offerd/list',
                    target: false
                }
            ]
        },
        {
            id: 'Budget And Expense',
            title: <FormattedMessage id="Budget And Expense" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: '/assets/images/icons/Budget.svg',
            children: [
                {
                    id: 'Budget Head',
                    title: <FormattedMessage id="Budget Head" />,
                    type: 'item',
                    url: '/budget/list',
                    target: false
                },
                {
                    id: 'Budget Allocation',
                    title: <FormattedMessage id="Budget Allocation" />,
                    type: 'item',
                    url: '/budget-allocation/list',
                    target: false
                },
                {
                    id: 'Expenses',
                    title: <FormattedMessage id="Expenses" />,
                    type: 'item',
                    url: '/expenses/list',
                    target: false
                },
                {
                    id: 'Budget & Expense Summary',
                    title: <FormattedMessage id="Budget & Expense Summary" />,
                    type: 'item',
                    url: '/report/expense/summary',
                    target: false
                }
            ]
        },
        {
            id: 'Feedback',
            title: <FormattedMessage id="Feedback" />,
            type: 'collapse',
            icon: '/assets/images/icons/Feedback_menu.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Feedback',
                    title: <FormattedMessage id="Feedback" />,
                    type: 'item',
                    url: '/feedback',
                    target: false
                },
                {
                    id: 'Faculty Rating report',
                    title: <FormattedMessage id="Faculty Rating report" />,
                    type: 'item',
                    url: '/faculty/rating/report',
                    target: false
                }
            ]
        },
        {
            id: 'Approval',
            title: <FormattedMessage id="Approval" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/82219588-1b8c-4c93-a06c-ee14445e18f9',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Scholarship',
                    title: <FormattedMessage id="Scholarship" />,
                    type: 'item',
                    url: '/scholarship/approval/list',
                    target: false
                },
                {
                    id: 'Partial Payment',
                    title: <FormattedMessage id="Partial Payment" />,
                    type: 'item',
                    url: '/partial/pay/approval/list',
                    target: false
                },
                {
                    id: 'Lesson Plan',
                    title: <FormattedMessage id="Lesson Plan" />,
                    type: 'item',
                    url: '/lesson/plan/approval',
                    target: false
                },
                {
                    id: 'Elective Approval',
                    title: <FormattedMessage id="Elective Approval" />,
                    type: 'item',
                    url: '/elective/subject/approval',
                    target: false
                },
                {
                    id: 'MarksApproval',
                    title: <FormattedMessage id="Marks Approval" />,
                    type: 'item',
                    url: '/exam/marks/approval',
                    target: false
                }
            ]
        },
        {
            id: 'Reports',
            title: <FormattedMessage id="Reports" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7f3fd1f5-e6e5-4f91-9c57-362c6fcd4770',
            children: [
                {
                    id: 'Collection',
                    title: <FormattedMessage id="Collection" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/collection',
                    breadcrumbs: false
                },
                {
                    id: 'Daily Admission Fees Collection ',
                    title: <FormattedMessage id="Daily Admission Fees Collection" />,
                    type: 'item',
                    url: '/daily/admission/collection/report',
                    target: false
                },
                {
                    id: 'Program Collection',
                    title: <FormattedMessage id="Program Collection" />,
                    type: 'item',
                    url: '/program/collection/report',
                    target: false
                },
                {
                    id: 'Program Due',
                    title: <FormattedMessage id="Program Due" />,
                    type: 'item',
                    url: '/program/due/report',
                    target: false
                },
                {
                    id: 'MultiProgramCollection',
                    title: <FormattedMessage id="MultiProgram Collection & Due" />,
                    type: 'item',
                    url: '/multiProgram/collection/report',
                    target: false
                },
                {
                    id: 'FeeAdjustments',
                    title: <FormattedMessage id="Fee Adjustments" />,
                    type: 'item',
                    url: '/adjustment/report',
                    target: false
                },
                {
                    id: 'FeeRefunds',
                    title: <FormattedMessage id="Fee Refunds" />,
                    type: 'item',
                    url: '/refundable/report',
                    target: false
                },
                {
                    id: 'CreditDeposits',
                    title: <FormattedMessage id="Credit Deposits" />,
                    type: 'item',
                    url: '/deposite/report',
                    target: false
                },
                {
                    id: 'Visitors',
                    title: <FormattedMessage id="Visitors" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/visitor',
                    breadcrumbs: false
                },
                {
                    id: 'StudentList',
                    title: <FormattedMessage id="Student List" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/Student',
                    breadcrumbs: false
                },
                {
                    id: 'Time Table Substitution',
                    title: <FormattedMessage id="Time Table Substitution" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/timetable/substitution/report',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'Exam',
        //     title: <FormattedMessage id="Exam" />,
        //     type: 'collapse',
        //     icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/8eb29028-4f44-417d-8d4d-711012dd0ea2',
        //     // url: '/admission/list',
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'EntranceExam',
        //             title: <FormattedMessage id="Entrance Exam" />,
        //             type: 'item',
        //             url: '/admission/entranceexam',
        //             target: false
        //         },
        //         {
        //             id: 'InternalAssessmentSetup',
        //             title: <FormattedMessage id="Internal Assessment Setup" />,
        //             type: 'item',
        //             url: '/internal/assessment/marks/group',
        //             target: false
        //         },
        //         {
        //             id: 'IsSubjectAllocation',
        //             title: <FormattedMessage id="IA Subject Allocation" />,
        //             type: 'item',
        //             url: '/ia/subjects/allocation',
        //             target: false
        //         },
        //         {
        //             id: 'ExamSetUp',
        //             title: <FormattedMessage id="Exam Setup" />,
        //             type: 'item',
        //             url: '/exam/setup',
        //             target: false
        //         },
        //         {
        //             id: 'ExamAttendance',
        //             title: <FormattedMessage id="Exam Attendance" />,
        //             type: 'item',
        //             url: '/exam/attendence',
        //             target: false
        //         },
        //         {
        //             id: 'ExamTimeTable',
        //             title: <FormattedMessage id="Exam TimeTable" />,
        //             type: 'item',
        //             url: '/exam/timetable',
        //             target: false
        //         },
        //         {
        //             id: 'InternalAssessmentMarksEntry',
        //             title: <FormattedMessage id="Internal Assessment Marks Entry" />,
        //             type: 'item',
        //             // url: '/exam/marks/internal-assessment',
        //             url: '/exam/internal/marks/entry',
        //             target: false
        //         },
        //         {
        //             id: 'SemesterMarksEntry',
        //             title: <FormattedMessage id="Semester Marks Entry" />,
        //             type: 'item',
        //             url: '/exam/marks/add',
        //             target: false
        //         },
        //         {
        //             id: 'ExamTabulationSheet',
        //             title: <FormattedMessage id="Exam Tabulation Sheet" />,
        //             type: 'item',
        //             url: '/exam/marksheet',
        //             target: false
        //         },
        //         {
        //             id: 'BacklogStudent',
        //             title: <FormattedMessage id="Backlog Student" />,
        //             type: 'item',
        //             url: '/backlog/students',
        //             target: false
        //         },
        //         {
        //             id: 'BacklogPaperEntry',
        //             title: <FormattedMessage id="Backlog Paper Entry" />,
        //             type: 'item',
        //             url: '/backlog/paper',
        //             target: false
        //         }
        //     ]
        // },
        {
            id: 'Setup',
            title: <FormattedMessage id="Setup" />,
            type: 'collapse',
            icon: '/assets/images/icons/Setup.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'School',
                    title: <FormattedMessage id="School" />,
                    type: 'item',
                    url: '/college/list',
                    target: false
                },
                {
                    id: 'Department',
                    title: <FormattedMessage id="Department" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/department',
                    breadcrumbs: false
                },
                {
                    id: 'Program',
                    title: <FormattedMessage id="Program" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/program',
                    breadcrumbs: false
                },
                {
                    id: 'Course',
                    title: <FormattedMessage id="Course" />,
                    type: 'item',
                    url: '/subject/list',
                    children: []
                },
                {
                    id: 'Section',
                    title: <FormattedMessage id="Section" />,
                    type: 'item',
                    url: '/section/list',
                    children: []
                },
                // {
                //     id: 'CourseAllocationByBatch',
                //     title: <FormattedMessage id="Course Allocation By Batch" />,
                //     type: 'item',
                //     url: '/subject/allocation/by/batch',
                //     children: []
                // },
                {
                    id: 'InternalAssessmentSetup',
                    title: <FormattedMessage id="Internal Assessment Setup" />,
                    type: 'item',
                    url: '/internal/assessment/marks/group',
                    target: false
                },
                // {
                //     id: 'IaCourseAllocation',
                //     title: <FormattedMessage id="IA Course Allocation" />,
                //     type: 'item',
                //     url: '/ia/subjects/allocation',
                //     target: false
                // },
                {
                    id: 'Descriptive & Objective Setup',
                    title: <FormattedMessage id="Descriptive & Objective Setup" />,
                    type: 'item',
                    url: '/subjective/objective/group',
                    target: false
                },
                {
                    id: 'ExamSetUp',
                    title: <FormattedMessage id="Exam Setup" />,
                    type: 'item',
                    url: '/exam/setup',
                    target: false
                },
                {
                    id: 'Halltickets',
                    title: <FormattedMessage id="Hall Tickets" />,
                    type: 'item',
                    url: '/generate/hallticket',
                    target: false
                }
            ]
        },
        {
            id: 'Master Setups',
            title: <FormattedMessage id="Master Setups" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7bfbeeff-4f8d-4397-84a6-58e27e3d0175',
            children: [
                {
                    id: 'AcademicYear',
                    title: <FormattedMessage id="Academic Year" />,
                    type: 'item',
                    url: '/institute/academic/year',
                    target: false
                },
                {
                    id: 'FinancialYear',
                    title: <FormattedMessage id="Financial Year" />,
                    type: 'item',
                    url: '/institute/financial/year',
                    target: false
                },
                {
                    id: 'AcademicSession',
                    title: <FormattedMessage id="Academic Session" />,
                    type: 'item',
                    url: '/academicsession/list',
                    target: false
                },
                {
                    id: 'Batch',
                    title: <FormattedMessage id="Batch" />,
                    type: 'item',
                    url: '/batch/list',
                    target: false
                },
                {
                    id: 'Bank',
                    title: <FormattedMessage id="Bank" />,
                    type: 'item',
                    url: '/bank/list',
                    target: false
                },
                {
                    id: 'Qualification',
                    title: <FormattedMessage id="Qualification" />,
                    type: 'item',
                    url: '/institute/qualification',
                    target: false
                },
                {
                    id: 'FeeScoreCategory',
                    title: <FormattedMessage id="Fee Score Category" />,
                    type: 'item',
                    url: '/fee/score/category',
                    target: false
                },
                {
                    id: 'Documents',
                    title: <FormattedMessage id="Documents" />,
                    type: 'item',
                    url: '/institute/document',
                    target: false
                },
                {
                    id: 'Leave',
                    title: <FormattedMessage id="Leave" />,
                    type: 'item',
                    url: '/leave/master',
                    children: []
                },
                {
                    id: 'Menu',
                    title: <FormattedMessage id="Menu" />,
                    type: 'item',
                    url: '/institute/menus/list',
                    children: []
                },
                {
                    id: 'Employee Category',
                    title: <FormattedMessage id="Employee Category" />,
                    type: 'item',
                    url: '/staffCategory',
                    children: []
                },
                {
                    id: 'Working Days',
                    title: <FormattedMessage id="Working Days" />,
                    type: 'item',
                    url: '/working/days',
                    children: []
                },
                {
                    id: 'Holidays',
                    title: <FormattedMessage id="Holidays" />,
                    type: 'item',
                    url: '/holiday',
                    children: []
                },
                {
                    id: 'Course',
                    title: <FormattedMessage id="Course" />,
                    type: 'item',
                    url: '/subject/list',
                    children: []
                },
                {
                    id: 'CourseAllocationByBatch',
                    title: <FormattedMessage id="Course Allocation By Batch" />,
                    type: 'item',
                    url: '/subject/allocation/by/batch',
                    children: []
                },
                {
                    id: 'GradeSystem',
                    title: <FormattedMessage id="Grade System" />,
                    type: 'item',
                    url: '/grade/list',
                    children: []
                },
                {
                    id: 'GenerateEnrollment',
                    title: <FormattedMessage id="Generate Enrollment" />,
                    type: 'item',
                    url: '/generate/enrollment',
                    children: []
                },
                {
                    id: 'programGroup',
                    title: <FormattedMessage id="Program Group" />,
                    type: 'item',
                    url: '/program/group',
                    children: []
                },
                {
                    id: 'Visitor Type',
                    title: <FormattedMessage id="Visitor Type" />,
                    type: 'item',
                    url: '/visitor/type',
                    children: []
                }
            ]
        },
        {
            id: 'Group',
            title: <FormattedMessage id="Group" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/bda8b652-0483-44f6-989f-fa58fb1db7e3',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Group List',
                    title: <FormattedMessage id="Group's Menu" />,
                    type: 'item',
                    url: '/institute/menus/access/add',
                    target: false
                }
            ]
        },
        {
            id: 'Settings',
            title: <FormattedMessage id="Settings" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/d08fd1af-b780-4eeb-b9d0-9b818b1c7026',
            children: [
                {
                    id: 'RegistrationEmailTemplate',
                    title: <FormattedMessage id="Registration Email Template" />,
                    type: 'item',
                    url: '/template/email/add',
                    target: false
                },
                {
                    id: 'DepartmentBuildings',
                    title: <FormattedMessage id="Department Blocks" />,
                    type: 'item',
                    url: '/department/building',
                    target: false
                },
                {
                    id: 'Day AttendanceSetup',
                    title: <FormattedMessage id="Day Attendance Setup" />,
                    type: 'item',
                    url: '/attendance/setup',
                    target: false
                },
                {
                    id: 'Institute Setting',
                    title: <FormattedMessage id="Institute Setting" />,
                    type: 'item',
                    url: '/institute/setup',
                    target: false
                },
                {
                    id: 'SecurityGuard',
                    title: <FormattedMessage id="Security Guard" />,
                    type: 'item',
                    url: '/securityguard/list',
                    target: false
                }
            ]
        }
    ];
    menus.children = staticMenuData;
};
const staticCOE = () => {
    const staticMenuData = [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/c59a076b-d4e1-4291-86ec-d71c2439d018',
            // url: '/institute/dashboard',
            breadcrumbs: false,
            children: [
                {
                    id: 'Management',
                    title: <FormattedMessage id="Management" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/management/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Institute',
                    title: <FormattedMessage id="Institute" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/institute/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Hostel',
                    title: <FormattedMessage id="Hostel" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/hostel/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Library',
                    title: <FormattedMessage id="Library" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/library/dashboard',
                    breadcrumbs: false,
                    children: []
                },
                {
                    id: 'Payment Collection',
                    title: <FormattedMessage id="Payment Collection" />,
                    type: 'item',
                    url: '/payments/dashboard',
                    target: false
                },
                // {
                //     id: 'FeeDueDashboard',
                //     title: <FormattedMessage id="Fee Due Dashboard" />,
                //     type: 'item',
                //     url: '/fee/flow/report',
                //     target: false
                // },
                {
                    id: 'ExamDashboard',
                    title: <FormattedMessage id="Exam Dashboard" />,
                    type: 'item',
                    url: '/exam/dashboard',
                    target: false
                },
                {
                    id: 'My Calendar',
                    title: <FormattedMessage id="My Calendar" />,
                    type: 'item',
                    url: '/calender/event',
                    target: false
                }
            ]
        },
        {
            id: 'Schools',
            title: <FormattedMessage id={intituteId === '64f6beb1a7bf157d349d821f' ? 'Colleges' : 'Schools'} />,
            type: 'mainMenu',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/55542155-8502-4aa2-b4f0-cdaedadca94f',
            url: '/college/list',
            breadcrumbs: false,
            children: []
        },
        {
            id: 'Admission',
            title: <FormattedMessage id="Admission" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/456740ed-6d32-45fc-bbac-afd56de816ae',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Registration',
                    title: <FormattedMessage id="Registration List" />,
                    type: 'item',
                    url: '/student/registration/list',
                    target: false
                },
                {
                    id: 'Admission',
                    title: <FormattedMessage id="Admission List" />,
                    type: 'item',
                    url: '/student/admission/list',
                    target: false
                },
                {
                    id: 'Offline Registration',
                    title: <FormattedMessage id="Offline Registration" />,
                    type: 'item',
                    url: '/offline/registration',
                    target: false
                },
                {
                    id: 'Offline Admission',
                    title: <FormattedMessage id="Offline Admission" />,
                    type: 'item',
                    url: '/offline/student/profile',
                    target: false
                },
                {
                    id: 'EntranceExam',
                    title: <FormattedMessage id="Entrance Exam" />,
                    type: 'item',
                    url: '/admission/entranceexam',
                    target: false
                },
                {
                    id: 'Distance Education',
                    title: <FormattedMessage id="Distance Education" />,
                    type: 'item',
                    url: '/distance/education/admission',
                    target: false
                },
                {
                    id: 'AdmissionCancel',
                    title: <FormattedMessage id="Admission Cancel List" />,
                    type: 'item',
                    url: '/discontinue/student/list',
                    children: []
                },
                {
                    id: 'PendingAdmissionCancelList',
                    title: <FormattedMessage id="Pending Admission Cancel List" />,
                    type: 'item',
                    url: '/admission/cancel/approval/list',
                    children: []
                },
                {
                    id: 'Change Program',
                    title: <FormattedMessage id="Change Program" />,
                    type: 'item',
                    url: '/change/program',
                    children: []
                },
                {
                    id: 'Change Degree',
                    title: <FormattedMessage id="Change Degree" />,
                    type: 'item',
                    url: '/change/degree',
                    children: []
                }
            ]
        },
        {
            id: 'Student',
            title: <FormattedMessage id="Student" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/ef7dc3cb-c921-4453-aaa6-0a5c211d5559',
            children: [
                {
                    id: 'Student Subject Group',
                    title: <FormattedMessage id="Student Subject Group" />,
                    type: 'item',
                    url: '/student-group',
                    children: []
                },
                {
                    id: 'Student Promotion',
                    title: <FormattedMessage id="Student Promotion" />,
                    type: 'item',
                    url: '/student/promotion',
                    children: []
                },
                {
                    id: 'AssignElectiveCourse',
                    title: <FormattedMessage id="Assign Elective Course" />,
                    type: 'item',
                    url: '/subject/allow/electives',
                    children: []
                },
                {
                    id: 'Apply For Scholarship',
                    title: <FormattedMessage id="Apply For Scholarship" />,
                    type: 'item',
                    url: '/payment/plan',
                    children: []
                },
                {
                    id: 'Active Students',
                    title: <FormattedMessage id="Active Students" />,
                    type: 'item',
                    url: '/student/accounts',
                    children: []
                },
                {
                    id: 'Parent Info Update',
                    title: <FormattedMessage id="Parent Info Update" />,
                    type: 'item',
                    url: '/student/accounts/info/update',
                    children: []
                },
                {
                    id: 'Student Enrollment',
                    title: <FormattedMessage id="Student Enrollment" />,
                    type: 'item',
                    url: '/student/enrollment-report',
                    children: []
                },
                {
                    id: 'StudentScholarship',
                    title: <FormattedMessage id="Students Scholarship" />,
                    type: 'item',
                    url: '/student/scholarship',
                    children: []
                },
                {
                    id: 'StudentAppDownload',
                    title: <FormattedMessage id="Student App Download" />,
                    type: 'item',
                    url: '/student/app/download/report',
                    children: []
                },
                {
                    id: 'Student Marksheet Download',
                    title: <FormattedMessage id="Student Marksheet Download" />,
                    type: 'item',
                    url: '/student/marksheet/download',
                    children: []
                },
                {
                    id: 'Student Face Registered',
                    title: <FormattedMessage id="Student Face Registered" />,
                    type: 'item',
                    url: '/student/face/register',
                    children: []
                }
            ]
        },
        {
            id: 'Account',
            title: <FormattedMessage id="Account" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b484bd36-07cf-4839-a624-0594c71ea452',
            // url: '/college/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Accounting Division',
                    title: <FormattedMessage id="Accounting Division" />,
                    type: 'item',
                    url: '/acounting/add',
                    target: false
                },
                {
                    id: 'Receipt Book',
                    title: <FormattedMessage id="Receipt Book" />,
                    type: 'item',
                    url: '/receipt/add',
                    target: false
                },
                {
                    id: 'Fee Code',
                    title: <FormattedMessage id="Fee Code" />,
                    type: 'item',
                    url: `/fee`,
                    target: false
                },
                {
                    id: 'Fee Mapping',
                    title: <FormattedMessage id="Fee Mapping" />,
                    type: 'item',
                    url: `/feeMapping`,
                    target: false
                },
                {
                    id: 'Fee Fine',
                    title: <FormattedMessage id="Fee Fine" />,
                    type: 'item',
                    url: `/feeFine`,
                    target: false
                }
            ]
        },
        {
            id: 'Fee',
            title: <FormattedMessage id="Fee" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/3da550a0-28e3-4717-8a2e-db11c3b4e5a0',
            breadcrumbs: false,
            children: [
                {
                    id: 'AdmissionFeesCollection',
                    title: <FormattedMessage id="Admission Fees Collection" />,
                    type: 'item',
                    url: '/offline/fee/payment',
                    target: false
                },
                {
                    id: 'FeeCollectionEntry',
                    title: <FormattedMessage id="Fee Collection Entry" />,
                    type: 'item',
                    url: '/student/offline/fee/payment',
                    target: false
                },
                {
                    id: 'Prospectus Fees Collection',
                    title: <FormattedMessage id="Prospectus Fees Collection" />,
                    type: 'item',
                    url: '/offline/registration/fees/payment',
                    target: false
                },
                {
                    id: 'Collected Fee',
                    title: <FormattedMessage id="Collected Fee" />,
                    type: 'item',
                    url: '/collected/fees/report',
                    target: false
                },
                // {
                //     id: 'Batch Due',
                //     title: <FormattedMessage id="Batch Due" />,
                //     type: 'item',
                //     url: '/due/fees/report',
                //     target: false
                // },
                {
                    id: 'Daily Fee Collection',
                    title: <FormattedMessage id="Daily Fee Collection" />,
                    type: 'item',
                    url: '/daily/collected/fees/report',
                    target: false
                },
                {
                    id: 'Daily Fee Collection Summary',
                    title: <FormattedMessage id="Daily Fee Collection Summary" />,
                    type: 'item',
                    url: '/daily/collection/summary',
                    target: false
                },
                {
                    id: 'Session Due',
                    title: <FormattedMessage id="Session Due" />,
                    type: 'item',
                    url: '/session/due/report',
                    target: false
                },
                {
                    id: 'Fee Refund / Adjust',
                    title: <FormattedMessage id="Fee Refund / Adjust" />,
                    type: 'item',
                    url: '/fee/refund',
                    target: false
                },
                {
                    id: 'Edit Receipt',
                    title: <FormattedMessage id="Edit Receipt" />,
                    type: 'item',
                    url: '/payment/receipt',
                    target: false
                },
                {
                    id: 'StudentFeeProfile',
                    title: <FormattedMessage id="Student Fee Profile" />,
                    type: 'item',
                    url: '/student/fees/profile',
                    target: false
                },
                {
                    id: 'BacklogImprovementFeeSetup',
                    title: <FormattedMessage id="Backlog/Improvement Fee Setup" />,
                    type: 'item',
                    url: '/backlog/improvement/list',
                    target: false
                }
                // {
                //     id: 'FeeDefaulterHallTicket',
                //     title: <FormattedMessage id="Fee Defaulter Hall Ticket" />,
                //     type: 'item',
                //     url: '/fee/defaulter/hall/ticket',
                //     target: false
                // },
                // {
                //     id: 'HallTicketRequestList',
                //     title: <FormattedMessage id="Hall Ticket Request List" />,
                //     type: 'item',
                //     url: '/hall/ticket/approval/request/list',
                //     target: false
                // }
            ]
        },

        {
            id: 'Employee',
            title: <FormattedMessage id="Employee" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/326ad56a-07b4-4fad-a462-13c8b528cfd2',
            url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Employee Registration',
                    title: <FormattedMessage id="Employee Registration" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/staff/registration',
                    target: false
                },
                {
                    id: 'Employee Mail Activation',
                    title: <FormattedMessage id="Employee Mail Activation" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/employee/accounts',
                    target: false
                },
                {
                    id: 'Assign Group',
                    title: <FormattedMessage id="Assign Group" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/assign/group',
                    target: false
                },
                {
                    id: 'PayRoll',
                    title: <FormattedMessage id="Pay Roll" />,
                    type: 'collapse',
                    // icon: '/assets/images/icons/Payroll.svg',
                    // url: '/admission/list',
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'PayRollComponentList',
                            title: <FormattedMessage id="Pay Roll Component List" />,
                            type: 'item',
                            url: '/payrole/master',
                            children: []
                        },
                        {
                            id: 'PayRollAllocation',
                            title: <FormattedMessage id="Pay Roll Allocation" />,
                            type: 'item',
                            url: '/payrole-allocation',
                            children: []
                        },
                        {
                            id: 'GenerateBulkPaySlip',
                            title: <FormattedMessage id="Generate Bulk Pay Slip" />,
                            type: 'item',
                            url: '/generate/bulk/pay/slip',
                            children: []
                        },
                        {
                            id: 'PayslipSummary',
                            title: <FormattedMessage id="Payslip Summary" />,
                            type: 'item',
                            url: '/payslip/summary',
                            children: []
                        },
                        {
                            id: 'PayslipSummary',
                            title: <FormattedMessage id="Monthly Bank Statement" />,
                            type: 'item',
                            url: '/monthly/bank/statement',
                            children: []
                        },
                        {
                            id: 'EmployeePaySlip',
                            title: <FormattedMessage id="Employee Pay Slip" />,
                            type: 'item',
                            url: '/employee/pay/slip',
                            children: []
                        }
                    ]
                },
                {
                    id: 'Leave',
                    title: <FormattedMessage id="Leave" />,
                    type: 'collapse',
                    // icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7b3b8d53-2647-4570-8ee7-1425a10df239',
                    // url: '/admission/list',
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'Apply Leave',
                            title: <FormattedMessage id="Apply Leave" />,
                            type: 'item',
                            url: '/teacher/leaves',
                            target: false
                        },
                        {
                            id: 'Leave Approve',
                            title: <FormattedMessage id="Leave Approve" />,
                            type: 'item',
                            url: '/teacher/leaves/approve',
                            target: false
                        },
                        {
                            id: 'UpdateLOP',
                            title: <FormattedMessage id="Update LOP" />,
                            type: 'item',
                            url: '/update/lop',
                            target: false
                        },
                        {
                            id: 'LeaveReport',
                            title: <FormattedMessage id="Leave Report" />,
                            type: 'item',
                            url: '/leave/report',
                            target: false
                        },
                        {
                            id: 'EmployeeLeave',
                            title: <FormattedMessage id="Employee Leave" />,
                            type: 'item',
                            url: '/employee/leave/report',
                            target: false
                        },
                        {
                            id: 'OverAllLeaveReport',
                            title: <FormattedMessage id="Over All Leave Report" />,
                            type: 'item',
                            url: '/overall/leave/report',
                            target: false
                        }
                    ]
                }

                // {
                //     id: 'DownloadFacultyTimeTable',
                //     title: <FormattedMessage id="Download Faculty TimeTable" />,
                //     type: 'item',
                //     // icon: icons.IconUserCheck,
                //     url: '/staff/timetable/download',
                //     target: false
                // },
                // {
                //     id: 'Staff Appraisal',
                //     title: <FormattedMessage id="Staff Appraisal" />,
                //     type: 'item',
                //     url: '/appraisal/staff',
                //     target: false
                // }
            ]
        },
        {
            id: 'Academics',
            title: <FormattedMessage id="Academics" />,
            type: 'collapse',
            icon: '/assets/images/icons/academics.svg',
            breadcrumbs: false,
            children: [
                {
                    id: 'FacultySubjectAllocation',
                    title: <FormattedMessage id="Faculty Subject Allocation" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/staff/subject/allocation',
                    target: false
                },
                // {
                //     id: 'StaffSubjectAllocation',
                //     title: <FormattedMessage id="Staff Subject Allocation" />,
                //     type: 'item',
                //     // icon: icons.IconUserCheck,
                //     url: '/staff/subject/allocation-new',
                //     target: false
                // },

                {
                    id: 'Time Table',
                    title: <FormattedMessage id="Time Table" />,
                    type: 'collapse',
                    // icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/4215da4f-32d1-4c01-a9d9-d9c6a997dcdb',
                    // url: '/admission/list',
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'TimeTableDashboard',
                            title: <FormattedMessage id="Time Table Dashboard" />,
                            type: 'item',
                            url: '/timetable/dashboard',
                            target: false
                        },
                        {
                            id: 'TimeTableList',
                            title: <FormattedMessage id="Time Table" />,
                            type: 'item',
                            url: '/timetable',
                            target: false
                        },
                        // {
                        //     id: 'GenerateTimeTable',
                        //     title: <FormattedMessage id="Generate Time Table" />,
                        //     type: 'item',
                        //     url: '/generateTimeTable',
                        //     target: false
                        // },
                        {
                            id: 'TimeSlot',
                            title: <FormattedMessage id="Time Slot" />,
                            type: 'item',
                            url: '/timeSlot',
                            children: []
                        },
                        {
                            id: 'DownloadTimeTable',
                            title: <FormattedMessage id="Download Time Table" />,
                            type: 'item',
                            url: '/download/timetable',
                            target: false
                        },
                        {
                            id: 'Faculty Wise Time Table',
                            title: <FormattedMessage id="Faculty Wise Time Table" />,
                            type: 'item',
                            url: '/faculty/wise/timetable',
                            target: false
                        }
                    ]
                },
                {
                    id: 'Lesson Plan',
                    title: <FormattedMessage id="Lesson Plan" />,
                    type: 'collapse',
                    breadcrumbs: false,
                    // icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b4a2661d-92e9-4b56-ae27-db1d0b756c31',
                    children: [
                        {
                            id: 'Lesson Plan',
                            title: <FormattedMessage id="Lesson Plan" />,
                            type: 'item',
                            url: '/institute/lessionplan/add',
                            children: []
                        },
                        {
                            id: 'DownloadLessonPlanPdf',
                            title: <FormattedMessage id="Download Lesson Plan Pdf" />,
                            type: 'item',
                            url: '/institute/lessionplan/pdf',
                            children: []
                        },
                        {
                            id: 'Class Porgess Report',
                            title: <FormattedMessage id="Class Progess Report" />,
                            type: 'item',
                            url: '/class/progress/report',
                            children: []
                        },
                        {
                            id: 'Class Allotment Report',
                            title: <FormattedMessage id="Class Allotment Report" />,
                            type: 'item',
                            url: '/class/allotment/report',
                            children: []
                        }
                    ]
                },
                {
                    id: 'Assignments',
                    title: <FormattedMessage id="Assignments" />,
                    type: 'item',
                    url: '/assignment',
                    target: false
                }
                // {
                //     id: 'Assignments',
                //     title: <FormattedMessage id="Assignments" />,
                //     type: 'collapse',
                //     breadcrumbs: false,
                //     icon: '/assets/images/icons/AssignmentMenuIcon.svg',
                //     children: [
                //         {
                //             id: 'Assignments',
                //             title: <FormattedMessage id="Assignments" />,
                //             type: 'item',
                //             url: '/assignment',
                //             target: false
                //         }
                //     ]
                // }
            ]
        },
        {
            id: 'Attendance',
            title: <FormattedMessage id="Attendance" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/fc4334e6-a1e8-4036-8842-38942d71a7c9',
            breadcrumbs: false,
            children: [
                {
                    id: 'StudentAttendance',
                    title: <FormattedMessage id="Student Attendance" />,
                    type: 'item',
                    url: '/student/attendance',
                    target: false
                },
                {
                    id: 'Student Daily Attendance',
                    title: <FormattedMessage id="Student Daily Attendance" />,
                    type: 'item',
                    url: '/student/daily/attendance',
                    target: false
                },
                {
                    id: 'Student Monthly Attendance',
                    title: <FormattedMessage id="Student Monthly Attendance" />,
                    type: 'item',
                    url: '/student/monthly/attendance',
                    target: false
                },
                {
                    id: 'EmployeeAttendance',
                    title: <FormattedMessage id="Employee Attendance" />,
                    type: 'item',
                    url: '/staff/attendance',
                    target: false
                },
                {
                    id: 'Employee Daily Attendance',
                    title: <FormattedMessage id="Employee Daily Attendance" />,
                    type: 'item',
                    url: '/employee/daily/attendance',
                    target: false
                },
                {
                    id: 'Employee Attendance (Subject-Wise)',
                    title: <FormattedMessage id="Employee Attendance (Subject-Wise)" />,
                    type: 'item',
                    url: '/faculty-attendance',
                    target: false
                },
                {
                    id: 'Employee Monthly Attendance',
                    title: <FormattedMessage id="Employee Monthly Attendance" />,
                    type: 'item',
                    url: '/employee/monthly/attendance',
                    target: false
                },
                {
                    id: 'Employee Attendance Summary',
                    title: <FormattedMessage id="Employee Attendance Summary" />,
                    type: 'item',
                    url: '/employee/attendance/summary',
                    target: false
                },
                {
                    id: 'Student Monthly Subject Attendance',
                    title: <FormattedMessage id="Student Monthly Subject Attendance" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/month/wise/attendance/report',
                    breadcrumbs: false
                },
                {
                    id: 'Faculty Punctuality Report',
                    title: <FormattedMessage id="Faculty Punctuality Report" />,
                    type: 'item',
                    url: '/faculty-attendance-report',
                    target: false
                }
            ]
        },
        {
            id: 'Announcements',
            title: <FormattedMessage id="Announcements" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/73ec3091-7d43-4639-ac1f-fb8737869787',
            children: [
                {
                    id: 'Announcement',
                    title: <FormattedMessage id="Announcement" />,
                    type: 'item',
                    url: '/announcements',
                    target: false
                }
            ]
        },
        {
            id: 'Exam',
            title: <FormattedMessage id="Exam" />,
            type: 'collapse',
            icon: '/assets/images/icons/Report.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'ExamSetUp',
                    title: <FormattedMessage id="Exam Setup" />,
                    type: 'item',
                    url: '/exam/setup',
                    target: false
                },
                {
                    id: 'Marks Entry',
                    title: <FormattedMessage id="Marks Entry" />,
                    type: 'collapse',
                    // icon: '/assets/images/icons/Exam_Entry.svg',
                    // url: '/admission/list',
                    breadcrumbs: false,
                    children: [
                        // {
                        //     id: 'Sessional Marks Entry',
                        //     title: <FormattedMessage id="Sessional Marks Entry" />,
                        //     type: 'item',
                        //     // url: '/exam/marks/internal-assessment',
                        //     url: '/sessional/marks/entry',
                        //     target: false
                        // },
                        {
                            id: 'InternalAssessmentMarksEntry',
                            title: <FormattedMessage id="Internal Assessment Marks Entry" />,
                            type: 'item',
                            // url: '/exam/marks/internal-assessment',
                            url: '/exam/internal/marks/entry',
                            target: false
                        },
                        {
                            id: 'SemesterMarksEntry',
                            title: <FormattedMessage id="Semester Marks Entry" />,
                            type: 'item',
                            url: '/exam/marks/add',
                            target: false
                        },
                        {
                            id: 'ExamAttendance',
                            title: <FormattedMessage id="Exam Attendance" />,
                            type: 'item',
                            url: '/exam/attendence',
                            target: false
                        }
                    ]
                },
                {
                    id: 'MarksApproval',
                    title: <FormattedMessage id="Marks Approval" />,
                    type: 'item',
                    url: '/exam/marks/approval',
                    target: false
                },
                {
                    id: 'Exam Report',
                    title: <FormattedMessage id="Exam Report" />,
                    type: 'collapse',
                    // icon: '/assets/images/icons/Report.svg',
                    // url: '/admission/list',
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'ExamTimeTable',
                            title: <FormattedMessage id="Exam TimeTable" />,
                            type: 'item',
                            url: '/exam/timetable',
                            target: false
                        },
                        {
                            id: 'ExamTabulationSheet',
                            title: <FormattedMessage id="Exam Tabulation Sheet" />,
                            type: 'item',
                            url: '/exam/marksheet',
                            target: false
                        },
                        {
                            id: 'BulkSemesterGradeSheets',
                            title: <FormattedMessage id="Bulk Semester Grade Sheets" />,
                            type: 'item',
                            url: '/exam/semestergradesheets',
                            target: false
                        },
                        {
                            id: 'StudentGradeSheet',
                            title: <FormattedMessage id="Student Grade Sheet" />,
                            type: 'item',
                            url: '/student/marksheet/download',
                            children: []
                        },
                        {
                            id: 'BacklogStudent',
                            title: <FormattedMessage id="Backlog Student" />,
                            type: 'item',
                            url: '/backlog/students',
                            target: false
                        },
                        {
                            id: 'OverallAcademicReport',
                            title: <FormattedMessage id="Overall Academic Report" />,
                            type: 'item',
                            url: '/overall/academic/report',
                            target: false
                        },
                        {
                            id: 'Academic Performance',
                            title: <FormattedMessage id="Academic Performance" />,
                            type: 'item',
                            url: '/exam/performance',
                            target: false
                        }
                    ]
                }
            ]
        },
        {
            id: 'Library',
            title: <FormattedMessage id="Library" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/b0510a3a-7a66-45c1-8378-11c20956f1d7',
            children: [
                {
                    id: 'LibraryCategory',
                    title: <FormattedMessage id="Library Category" />,
                    type: 'item',
                    url: '/library/Category',
                    target: false
                },
                {
                    id: 'LibraryCategoryType',
                    title: <FormattedMessage id="Library Category Type" />,
                    type: 'item',
                    url: '/library/Categorytype',
                    target: false
                },
                {
                    id: 'AddBook',
                    title: <FormattedMessage id="Add Book" />,
                    type: 'item',
                    url: '/library/AddBook',
                    target: false
                },
                {
                    id: 'Issue/Return Book',
                    title: <FormattedMessage id="Issue/Return Book" />,
                    type: 'item',
                    url: '/library/issuebook',
                    target: false
                },

                {
                    id: 'Library Settings',
                    title: <FormattedMessage id="Library Settings" />,
                    type: 'item',
                    url: '/library/Setting',
                    target: false
                }
            ]
        },
        {
            id: 'Hostel',
            title: <FormattedMessage id="Hostel" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/c27020f6-e843-46c6-8752-96ccd6c07157',
            children: [
                {
                    id: 'Hostel Building',
                    title: <FormattedMessage id="Hostel Building" />,
                    type: 'item',
                    url: '/hostel/buildings/add',
                    children: []
                },
                {
                    id: 'Rooms',
                    title: <FormattedMessage id="Rooms" />,
                    type: 'item',
                    url: '/hostel/rooms/add',
                    children: []
                },
                {
                    id: 'Rooms Category',
                    title: <FormattedMessage id="Rooms Category" />,
                    type: 'item',
                    url: '/room/category/add',
                    children: []
                },
                {
                    id: 'StudentAllocation',
                    title: <FormattedMessage id="Student Allocation" />,
                    type: 'item',
                    url: '/hostel/student/allocation',
                    target: false
                },
                {
                    id: 'HostelFeeAllocation',
                    title: <FormattedMessage id="Hostel Fee Allocation" />,
                    type: 'item',
                    url: '/hostel/fee/allocation',
                    target: false
                },
                {
                    id: 'Viewstudentlist ',
                    title: <FormattedMessage id="View Student List" />,
                    type: 'item',
                    url: '/hostel/student/details',
                    target: false
                },
                {
                    id: 'CheckoutRequest',
                    title: <FormattedMessage id="Checkout Request" />,
                    type: 'item',
                    url: '/hostel/checkout/list',
                    target: false
                },
                {
                    id: 'CheckoutRequestApproved',
                    title: <FormattedMessage id="Checkout Approved List" />,
                    type: 'item',
                    url: '/hostel/checkout/approved/student/list',
                    target: false
                },
                {
                    id: 'HostelReports',
                    title: <FormattedMessage id="Hostel Occupancy Reports" />,
                    type: 'item',
                    url: '/hostel/reports',
                    target: false
                },
                {
                    id: 'HostelAttendanceReport',
                    title: <FormattedMessage id="Hostel Attendance Report" />,
                    type: 'item',
                    url: '/hostel/attendance/report',
                    target: false
                },
                {
                    id: 'DiscontinuedStudents',
                    title: <FormattedMessage id="Discontinued Students" />,
                    type: 'item',
                    url: '/hostel/discontinued/students/list',
                    target: false
                },
                {
                    id: 'HostelBusCollectionAndDue',
                    title: <FormattedMessage id="Hostel Collection And Due" />,
                    type: 'item',
                    url: '/hostel/monthly/collection/report',
                    target: false
                },
                {
                    id: 'CollectionDueByHostelBuilding',
                    title: <FormattedMessage id="Collection / Due By Hostel Building" />,
                    type: 'item',
                    url: '/collection/report/by/building',
                    target: false
                },
                {
                    id: 'StudentHostelOverDueReport',
                    title: <FormattedMessage id="Student Hostel Overdue Report" />,
                    type: 'item',
                    url: '/student/hostel/overdue/report',
                    target: false
                }
            ]
        },
        {
            id: 'Transport',
            title: <FormattedMessage id="Transport" />,
            type: 'collapse',
            icon: '/assets/images/icons/Transport.svg',
            breadcrumbs: false,
            children: [
                {
                    id: 'BusStoppage',
                    title: <FormattedMessage id="Bus Stoppage" />,
                    type: 'item',
                    url: '/bus/stoppage',
                    target: false
                },
                {
                    id: 'StudentAllocation',
                    title: <FormattedMessage id="Student Allocation" />,
                    type: 'item',
                    url: '/transport/allocate/student',
                    target: false
                },
                {
                    id: 'TransportOccupancyReport',
                    title: <FormattedMessage id="Transport Occupancy Report" />,
                    type: 'item',
                    url: '/transport/occupancy/report',
                    target: false
                },
                {
                    id: 'DiscontinueStudentList',
                    title: <FormattedMessage id="Discontinue Student List" />,
                    type: 'item',
                    url: '/transport/discontinue/list',
                    target: false
                },
                {
                    id: 'TransportBusCollectionAndDue',
                    title: <FormattedMessage id="Transport Collection And Due" />,
                    type: 'item',
                    url: '/transport/collection/report',
                    target: false
                }
            ]
        },

        {
            id: 'Placement',
            title: <FormattedMessage id="Placement" />,
            type: 'collapse',
            icon: '/assets/images/icons/Placement.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Dashboard',
                    title: <FormattedMessage id="Dashboard" />,
                    type: 'item',
                    url: '/placement/dashboard',
                    target: false
                },
                {
                    id: 'Companies',
                    title: <FormattedMessage id="Companies" />,
                    type: 'item',
                    url: '/companies/list',
                    target: false
                },
                {
                    id: 'Jobs',
                    title: <FormattedMessage id="Jobs" />,
                    type: 'item',
                    url: '/create/jobs/openings',
                    target: false
                },
                {
                    id: 'Job Tracker',
                    title: <FormattedMessage id="Job Tracker" />,
                    type: 'item',
                    url: '/job/tracker',
                    target: false
                },
                {
                    id: 'Student Report',
                    title: <FormattedMessage id="Student Report" />,
                    type: 'item',
                    url: '/student/report',
                    target: false
                },
                {
                    id: 'Qualification',
                    title: <FormattedMessage id="Qualification" />,
                    type: 'item',
                    url: '/placement/qualification',
                    target: false
                },
                {
                    id: 'Placement Setup',
                    title: <FormattedMessage id="Placement Setup" />,
                    type: 'item',
                    url: '/placementweeks',
                    target: false
                },
                {
                    id: 'Job Posting Approval',
                    title: <FormattedMessage id="Job Posting Approval" />,
                    type: 'item',
                    url: '/comapny/job/list',
                    target: false
                },
                {
                    id: 'Prospective Companies',
                    title: <FormattedMessage id="Prospective Companies" />,
                    type: 'item',
                    url: '/outreach/companies',
                    target: false
                }
            ]
        },
        {
            id: 'Alumni',
            title: <FormattedMessage id="Alumni" />,
            type: 'collapse',
            icon: '/assets/images/icons/Alumni.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Alumni Report',
                    title: <FormattedMessage id="Alumni Report" />,
                    type: 'item',
                    url: '/student/alumni',
                    target: false
                }
            ]
        },
        {
            id: 'Careers',
            title: <FormattedMessage id="Careers" />,
            type: 'collapse',
            icon: '/assets/images/icons/HR.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Hiring Process',
                    title: <FormattedMessage id="Hiring Process" />,
                    type: 'item',
                    url: '/hiring-process',
                    target: false
                },
                {
                    id: 'Skills',
                    title: <FormattedMessage id="Skills" />,
                    type: 'item',
                    url: '/skills',
                    target: false
                },
                {
                    id: 'Job Post Request',
                    title: <FormattedMessage id="Job Post Request" />,
                    type: 'item',
                    url: '/job-post-request',
                    target: false
                },
                {
                    id: 'Create Job Post',
                    title: <FormattedMessage id="Create Job Post" />,
                    type: 'item',
                    url: '/job-post-create',
                    target: false
                },
                {
                    id: 'Application List',
                    title: <FormattedMessage id="Application List" />,
                    type: 'item',
                    url: '/job/application/list',
                    target: false
                },
                {
                    id: 'Offered Candidates',
                    title: <FormattedMessage id="Offered Candidates" />,
                    type: 'item',
                    url: '/package/offerd/list',
                    target: false
                }
            ]
        },
        {
            id: 'Feedback',
            title: <FormattedMessage id="Feedback" />,
            type: 'collapse',
            icon: '/assets/images/icons/Feedback_menu.svg',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Feedback',
                    title: <FormattedMessage id="Feedback" />,
                    type: 'item',
                    url: '/feedback',
                    target: false
                },
                {
                    id: 'Faculty Rating report',
                    title: <FormattedMessage id="Faculty Rating report" />,
                    type: 'item',
                    url: '/faculty/rating/report',
                    target: false
                }
            ]
        },
        {
            id: 'Approval',
            title: <FormattedMessage id="Approval" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/82219588-1b8c-4c93-a06c-ee14445e18f9',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'Scholarship',
                    title: <FormattedMessage id="Scholarship" />,
                    type: 'item',
                    url: '/scholarship/approval/list',
                    target: false
                },
                {
                    id: 'Partial Payment',
                    title: <FormattedMessage id="Partial Payment" />,
                    type: 'item',
                    url: '/partial/pay/approval/list',
                    target: false
                },
                {
                    id: 'Lesson Plan',
                    title: <FormattedMessage id="Lesson Plan" />,
                    type: 'item',
                    url: '/lesson/plan/approval',
                    target: false
                },
                {
                    id: 'Elective Approval',
                    title: <FormattedMessage id="Elective Approval" />,
                    type: 'item',
                    url: '/elective/subject/approval',
                    target: false
                }
            ]
        },
        {
            id: 'Reports',
            title: <FormattedMessage id="Reports" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7f3fd1f5-e6e5-4f91-9c57-362c6fcd4770',
            children: [
                {
                    id: 'Collection',
                    title: <FormattedMessage id="Collection" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/collection',
                    breadcrumbs: false
                },
                {
                    id: 'Daily Admission Fees Collection ',
                    title: <FormattedMessage id="Daily Admission Fees Collection" />,
                    type: 'item',
                    url: '/daily/admission/collection/report',
                    target: false
                },
                {
                    id: 'Program Collection',
                    title: <FormattedMessage id="Program Collection" />,
                    type: 'item',
                    url: '/program/collection/report',
                    target: false
                },
                {
                    id: 'Program Due',
                    title: <FormattedMessage id="Program Due" />,
                    type: 'item',
                    url: '/program/due/report',
                    target: false
                },
                {
                    id: 'MultiProgramCollection',
                    title: <FormattedMessage id="MultiProgram Collection & Due" />,
                    type: 'item',
                    url: '/multiProgram/collection/report',
                    target: false
                },
                {
                    id: 'FeeAdjustments',
                    title: <FormattedMessage id="Fee Adjustments" />,
                    type: 'item',
                    url: '/adjustment/report',
                    target: false
                },
                {
                    id: 'FeeRefunds',
                    title: <FormattedMessage id="Fee Refunds" />,
                    type: 'item',
                    url: '/refundable/report',
                    target: false
                },
                {
                    id: 'CreditDeposits',
                    title: <FormattedMessage id="Credit Deposits" />,
                    type: 'item',
                    url: '/deposite/report',
                    target: false
                },
                {
                    id: 'Visitors',
                    title: <FormattedMessage id="Visitors" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/visitor',
                    breadcrumbs: false
                },
                {
                    id: 'StudentList',
                    title: <FormattedMessage id="Student List" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/Student',
                    breadcrumbs: false
                },
                {
                    id: 'Time Table Substitution',
                    title: <FormattedMessage id="Time Table Substitution" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/timetable/substitution/report',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'Exam',
        //     title: <FormattedMessage id="Exam" />,
        //     type: 'collapse',
        //     icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/8eb29028-4f44-417d-8d4d-711012dd0ea2',
        //     // url: '/admission/list',
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'EntranceExam',
        //             title: <FormattedMessage id="Entrance Exam" />,
        //             type: 'item',
        //             url: '/admission/entranceexam',
        //             target: false
        //         },
        //         {
        //             id: 'InternalAssessmentSetup',
        //             title: <FormattedMessage id="Internal Assessment Setup" />,
        //             type: 'item',
        //             url: '/internal/assessment/marks/group',
        //             target: false
        //         },
        //         {
        //             id: 'IsSubjectAllocation',
        //             title: <FormattedMessage id="IA Subject Allocation" />,
        //             type: 'item',
        //             url: '/ia/subjects/allocation',
        //             target: false
        //         },
        //         {
        //             id: 'ExamSetUp',
        //             title: <FormattedMessage id="Exam Setup" />,
        //             type: 'item',
        //             url: '/exam/setup',
        //             target: false
        //         },
        //         {
        //             id: 'ExamAttendance',
        //             title: <FormattedMessage id="Exam Attendance" />,
        //             type: 'item',
        //             url: '/exam/attendence',
        //             target: false
        //         },
        //         {
        //             id: 'ExamTimeTable',
        //             title: <FormattedMessage id="Exam TimeTable" />,
        //             type: 'item',
        //             url: '/exam/timetable',
        //             target: false
        //         },
        //         {
        //             id: 'InternalAssessmentMarksEntry',
        //             title: <FormattedMessage id="Internal Assessment Marks Entry" />,
        //             type: 'item',
        //             // url: '/exam/marks/internal-assessment',
        //             url: '/exam/internal/marks/entry',
        //             target: false
        //         },
        //         {
        //             id: 'SemesterMarksEntry',
        //             title: <FormattedMessage id="Semester Marks Entry" />,
        //             type: 'item',
        //             url: '/exam/marks/add',
        //             target: false
        //         },
        //         {
        //             id: 'ExamTabulationSheet',
        //             title: <FormattedMessage id="Exam Tabulation Sheet" />,
        //             type: 'item',
        //             url: '/exam/marksheet',
        //             target: false
        //         },
        //         {
        //             id: 'BacklogStudent',
        //             title: <FormattedMessage id="Backlog Student" />,
        //             type: 'item',
        //             url: '/backlog/students',
        //             target: false
        //         },
        //         {
        //             id: 'BacklogPaperEntry',
        //             title: <FormattedMessage id="Backlog Paper Entry" />,
        //             type: 'item',
        //             url: '/backlog/paper',
        //             target: false
        //         }
        //     ]
        // },
        {
            id: 'Setup',
            title: <FormattedMessage id="Setup" />,
            type: 'collapse',
            icon: '/assets/images/icons/Setup.svg',
            // url: '/admission/list',
            breadcrumbs: false,
            children: [
                {
                    id: 'School',
                    title: <FormattedMessage id="School" />,
                    type: 'item',
                    url: '/college/list',
                    target: false
                },
                {
                    id: 'Department',
                    title: <FormattedMessage id="Department" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/department',
                    breadcrumbs: false
                },
                {
                    id: 'Program',
                    title: <FormattedMessage id="Program" />,
                    type: 'item',
                    // icon: icons.IconUserCheck,
                    url: '/report/program',
                    breadcrumbs: false
                },
                {
                    id: 'Course',
                    title: <FormattedMessage id="Course" />,
                    type: 'item',
                    url: '/subject/list',
                    children: []
                },
                {
                    id: 'Section',
                    title: <FormattedMessage id="Section" />,
                    type: 'item',
                    url: '/section/list',
                    children: []
                },
                // {
                //     id: 'CourseAllocationByBatch',
                //     title: <FormattedMessage id="Course Allocation By Batch" />,
                //     type: 'item',
                //     url: '/subject/allocation/by/batch',
                //     children: []
                // },
                {
                    id: 'InternalAssessmentSetup',
                    title: <FormattedMessage id="Internal Assessment Setup" />,
                    type: 'item',
                    url: '/internal/assessment/marks/group',
                    target: false
                },
                // {
                //     id: 'IaCourseAllocation',
                //     title: <FormattedMessage id="IA Course Allocation" />,
                //     type: 'item',
                //     url: '/ia/subjects/allocation',
                //     target: false
                // },
                {
                    id: 'Descriptive & Objective Setup',
                    title: <FormattedMessage id="Descriptive & Objective Setup" />,
                    type: 'item',
                    url: '/subjective/objective/group',
                    target: false
                },
                {
                    id: 'Halltickets',
                    title: <FormattedMessage id="Hall Tickets" />,
                    type: 'item',
                    url: '/generate/hallticket',
                    target: false
                }
            ]
        },
        {
            id: 'Master Setups',
            title: <FormattedMessage id="Master Setups" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/7bfbeeff-4f8d-4397-84a6-58e27e3d0175',
            children: [
                {
                    id: 'AcademicYear',
                    title: <FormattedMessage id="Academic Year" />,
                    type: 'item',
                    url: '/institute/academic/year',
                    target: false
                },
                {
                    id: 'FinancialYear',
                    title: <FormattedMessage id="Financial Year" />,
                    type: 'item',
                    url: '/institute/financial/year',
                    target: false
                },
                {
                    id: 'AcademicSession',
                    title: <FormattedMessage id="Academic Session" />,
                    type: 'item',
                    url: '/academicsession/list',
                    target: false
                },
                {
                    id: 'Batch',
                    title: <FormattedMessage id="Batch" />,
                    type: 'item',
                    url: '/batch/list',
                    target: false
                },
                {
                    id: 'Bank',
                    title: <FormattedMessage id="Bank" />,
                    type: 'item',
                    url: '/bank/list',
                    target: false
                },
                {
                    id: 'Qualification',
                    title: <FormattedMessage id="Qualification" />,
                    type: 'item',
                    url: '/institute/qualification',
                    target: false
                },
                {
                    id: 'FeeScoreCategory',
                    title: <FormattedMessage id="Fee Score Category" />,
                    type: 'item',
                    url: '/fee/score/category',
                    target: false
                },
                {
                    id: 'Documents',
                    title: <FormattedMessage id="Documents" />,
                    type: 'item',
                    url: '/institute/document',
                    target: false
                },
                {
                    id: 'Leave',
                    title: <FormattedMessage id="Leave" />,
                    type: 'item',
                    url: '/leave/master',
                    children: []
                },
                {
                    id: 'Menu',
                    title: <FormattedMessage id="Menu" />,
                    type: 'item',
                    url: '/institute/menus/list',
                    children: []
                },
                {
                    id: 'Employee Category',
                    title: <FormattedMessage id="Employee Category" />,
                    type: 'item',
                    url: '/staffCategory',
                    children: []
                },
                {
                    id: 'Working Days',
                    title: <FormattedMessage id="Working Days" />,
                    type: 'item',
                    url: '/working/days',
                    children: []
                },
                {
                    id: 'Holidays',
                    title: <FormattedMessage id="Holidays" />,
                    type: 'item',
                    url: '/holiday',
                    children: []
                },
                {
                    id: 'Course',
                    title: <FormattedMessage id="Course" />,
                    type: 'item',
                    url: '/subject/list',
                    children: []
                },
                {
                    id: 'CourseAllocationByBatch',
                    title: <FormattedMessage id="Course Allocation By Batch" />,
                    type: 'item',
                    url: '/subject/allocation/by/batch',
                    children: []
                },
                {
                    id: 'GradeSystem',
                    title: <FormattedMessage id="Grade System" />,
                    type: 'item',
                    url: '/grade/list',
                    children: []
                },
                {
                    id: 'GenerateEnrollment',
                    title: <FormattedMessage id="Generate Enrollment" />,
                    type: 'item',
                    url: '/generate/enrollment',
                    children: []
                },
                {
                    id: 'programGroup',
                    title: <FormattedMessage id="Program Group" />,
                    type: 'item',
                    url: '/program/group',
                    children: []
                },
                {
                    id: 'Visitor Type',
                    title: <FormattedMessage id="Visitor Type" />,
                    type: 'item',
                    url: '/visitor/type',
                    children: []
                }
            ]
        },
        {
            id: 'Group',
            title: <FormattedMessage id="Group" />,
            type: 'collapse',
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/bda8b652-0483-44f6-989f-fa58fb1db7e3',
            // url: '/staff/registration',
            breadcrumbs: false,
            children: [
                {
                    id: 'Group List',
                    title: <FormattedMessage id="Group's Menu" />,
                    type: 'item',
                    url: '/institute/menus/access/add',
                    target: false
                }
            ]
        },
        {
            id: 'Settings',
            title: <FormattedMessage id="Settings" />,
            type: 'collapse',
            breadcrumbs: false,
            icon: 'https://github.com/skimtiyaz004/ustm/assets/35215638/d08fd1af-b780-4eeb-b9d0-9b818b1c7026',
            children: [
                {
                    id: 'RegistrationEmailTemplate',
                    title: <FormattedMessage id="Registration Email Template" />,
                    type: 'item',
                    url: '/template/email/add',
                    target: false
                },
                {
                    id: 'DepartmentBuildings',
                    title: <FormattedMessage id="Department Blocks" />,
                    type: 'item',
                    url: '/department/building',
                    target: false
                },
                {
                    id: 'Day AttendanceSetup',
                    title: <FormattedMessage id="Day Attendance Setup" />,
                    type: 'item',
                    url: '/attendance/setup',
                    target: false
                },
                {
                    id: 'Institute Setting',
                    title: <FormattedMessage id="Institute Setting" />,
                    type: 'item',
                    url: '/institute/setup',
                    target: false
                },
                {
                    id: 'SecurityGuard',
                    title: <FormattedMessage id="Security Guard" />,
                    type: 'item',
                    url: '/securityguard/list',
                    target: false
                }
            ]
        }
    ];
    menus.children = staticMenuData;
};

const CompanyMenu = () => {
    const staticMenuData = [
        {
            id: 'DashBoard',
            title: <FormattedMessage id="DashBoard" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/dashboard',
            children: []
        },
        // {
        //     id: 'Add Skills',
        //     title: <FormattedMessage id="Add Skills" />,
        //     type: 'item',
        //     // icon: icons.IconUserCheck,
        //     breadcrumbs: false,
        //     url: '/skills/list',
        //     children: []
        // },
        {
            id: 'Company Details',
            title: <FormattedMessage id="Company Details" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/details',
            children: []
        },
        // {
        //     id: 'Placemnet Week',
        //     title: <FormattedMessage id="Placemnet Week" />,
        //     type: 'item',
        //     // icon: icons.IconUserCheck,
        //     breadcrumbs: false,
        //     url: '/placementweeks',
        //     children: []
        // },
        {
            id: 'Create Job',
            title: <FormattedMessage id="Create Job" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/create/jobs',
            children: []
        },
        {
            id: 'Job Tracker',
            title: <FormattedMessage id="Job Tracker" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/job/tracker',
            children: []
        },
        {
            id: 'Hired Cadidates',
            title: <FormattedMessage id="Hired Cadidates" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/hired/candidates/list',
            children: []
        }
    ];
    menus.children = staticMenuData;
};

const role = localStorage.getItem('role');
const userType = localStorage.getItem('userTypes');

if (role === 'ROLE_SUPER_ADMIN') {
    staticMenu();
} else if (role === 'ROLE_STUDENT') {
    StudentMenu();
} else if (role === 'ROLE_HIRER') {
    CompanyMenu();
} else if (role === 'ROLE_STUDENT_ADMISSION') {
    staticMenuStudent();
} else if (role === 'ROLE_COLLEGE_ADMIN' && userType === 'Institute_Admin') {
    staticInstitute();
} else if (role === 'ROLE_ADMIN') {
    staticCOE();
} else if (role === 'ROLE_COLLEGE_SUB_ADMIN' && userType === 'COE') {
    staticCOE();
} else if (role === 'ROLE_COLLEGE_SUB_ADMIN' && userType !== 'COE') {
    menuInit();
} else if (role === 'ROLE_COLLEGE_ADMIN' && userType === 'Dean') {
    menuInit();
} else {
    menuInit();
}

export default menus;
