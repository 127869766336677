import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    TablePagination
} from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import React, { useEffect, useRef, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import AddNewAllocation from './AddNewAllocation';
import DataNotAvailablePage from 'reusable-components/data-not-found';

const headers = {
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
        'Content-Type': 'application/json'
    }
};

const initialValues = {
    isLoading: false,
    sessionId: '',
    departmentId: '',
    staffId: '',
    sessionList: [],
    departmentList: [],
    staffList: [],
    allocationList: [],
    subjectAllocation: [
        {
            departmentId: '',
            courseList: [],
            courseId: '',
            semList: 0,
            sem: 0,
            subjectList: [],
            subjectId: ''
        }
    ]
};

const FacultySubjectAllocation = () => {
    const [isShow, setIsShow] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const showMessage = useMessageDispatcher();
    const formikRef = useRef();
    const instituteId = localStorage.getItem('id');

    const fetchSessions = async () => {
        try {
            const response = await axios.get(`/v1/session/list`, headers);
            if (response.status === 200) {
                formikRef?.current?.setFieldValue('sessionList', response?.data?.SessionList);
                // if (response.data?.SessionList[0]?.status === true) {
                //     const defaultSession = response.data.SessionList[0].id;
                //     formikRef?.current?.setFieldValue('sessionId', defaultSession);
                // }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`/v1/departments/list/by-college?instituteId=${instituteId}`, headers);
            if (response.status === 200) {
                formikRef?.current?.setFieldValue('departmentList', response?.data?.departmentList);
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    const fetchCourses = async (departmentId, i) => {
        try {
            const response = await axios.get(`/v1/courses/list/by-department?departmentId=${departmentId}`, headers);
            if (response.status === 200) {
                const courses = response.data.courseList || [];
                formikRef?.current?.setFieldValue(`subjectAllocation[${i}].courseList`, courses);
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    const fetchSubjects = async (sem, courseId, i) => {
        try {
            const response = await axios.get(`/v1/subjects/list?semesterNumber=${sem}&courseId=${courseId}`, headers);
            if (response.status === 200) {
                const subjects = response.data.subjectList || [];
                formikRef?.current?.setFieldValue(`subjectAllocation[${i}].subjectList`, subjects);
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const fetchStaffs = async (departmentId) => {
        try {
            const response = await axios.get(`/v1/staffs/list/filter?active=true&departmentId=${departmentId || ''}`, headers);
            if (response.status === 200) {
                formikRef?.current?.setFieldValue('staffList', response?.data?.StaffDetailList);
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const fetchSubjectAllocations = async (values) => {
        try {
            const response = await axios.get(
                `/v1/staffs/subjects/get/session-wise?sessionId=${values?.sessionId}&userId=${values?.staffId}`,
                headers
            );
            if (response.status === 200) {
                const allocations = response?.data?.data?.allocation || [];
                formikRef?.current?.setFieldValue('allocationList', allocations);
            }
        } catch (error) {
            formikRef?.current?.setFieldValue('allocationList', []);
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    const handleUpdateAllocation = async (values) => {
        const data = values?.subjectAllocation?.map((allocation) => ({
            courseId: allocation?.courseId,
            sem: allocation?.sem,
            subjectId: allocation?.subjectId
        }));
        // const payload = [...values?.allocationList, ...data];
        const payload = [
            ...values?.allocationList?.map((item) => ({
                courseId: item.courseId,
                sem: item.sem,
                subjectId: item?.subjectDetail?.subjectId
            })),
            ...data
        ];

        try {
            const response = await axios.post(
                `/v1/staffs/subjects/allocate/session-wise?sessionId=${values?.sessionId}&userId=${values?.staffId}`,
                payload,
                headers
            );
            if (response.status === 200) {
                formikRef?.current?.setFieldValue('subjectAllocation', [
                    {
                        departmentId: '',
                        courseList: [],
                        courseId: '',
                        semList: 0,
                        sem: 0,
                        subjectList: [],
                        subjectId: ''
                    }
                ]);
                setIsShow(false);
                showMessage({ message: response.data.Msg, color: 'success' });
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderTableContent = () => {
        if (formikRef?.current?.values?.isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={3} align="center">
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            );
        }

        if (formikRef?.current?.values?.allocationList?.length > 0) {
            return formikRef?.current?.values?.allocationList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((allocation, index) => (
                    <TableRow key={index}>
                        <TableCell>{allocation?.subjectDetail?.subjectMini?.courseName || 'N/A'}</TableCell>
                        <TableCell>{allocation?.sem || 'N/A'}</TableCell>
                        <TableCell>{allocation?.subjectDetail?.subjectMini?.subjectName || 'N/A'}</TableCell>
                    </TableRow>
                ));
        }
        return (
            <TableRow>
                <TableCell colSpan={3} align="center">
                    <Typography variant="h6" color="textSecondary">
                        No allocations available.
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    useEffect(() => {
        fetchSessions();
        fetchDepartments();
        fetchStaffs();
    }, []);

    return (
        <MainCard>
            <Formik initialValues={initialValues} innerRef={formikRef} onSubmit={handleUpdateAllocation}>
                {({ values, setFieldValue, handleChange }) => (
                    <>
                        {isShow ? (
                            <AddNewAllocation
                                values={values}
                                setIsShow={setIsShow}
                                setFieldValue={setFieldValue}
                                fetchCourses={fetchCourses}
                                fetchSubjects={fetchSubjects}
                            />
                        ) : (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="session-select">Select Batch</InputLabel>
                                        <Select
                                            labelId="session-select"
                                            id="sessionId"
                                            name="sessionId"
                                            value={values?.sessionId}
                                            onChange={(event) => handleChange(event)}
                                            label="Select Batch"
                                        >
                                            {values?.sessionList?.map((items) => (
                                                <MenuItem value={items.id} key={items.id}>
                                                    {items.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Autocomplete
                                        fullWidth
                                        id="departmentId"
                                        name="departmentId"
                                        options={values?.departmentList}
                                        getOptionLabel={(option) => option?.name.toUpperCase() || ''}
                                        onChange={(e, value) => {
                                            const department = values?.departmentList?.find((elem) => elem?.id === value?.id);
                                            formikRef?.current?.setFieldValue('departmentId', department?.id);
                                            fetchStaffs(department?.id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Department *" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="staffId"
                                            options={values?.staffList}
                                            getOptionLabel={(option) => option?.basicDetail?.staffName || ''}
                                            isOptionEqualToValue={(option, value) => option.userId === value}
                                            onChange={(event, value) => {
                                                const selectedValue = value?.userId || '';
                                                setFieldValue('staffId', selectedValue);
                                                console.log(selectedValue, 'selectedValueselectedValue');
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Select Faculty *" />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={1}>
                                    <Button variant="contained" color="primary" onClick={() => fetchSubjectAllocations(values)}>
                                        Search
                                    </Button>
                                </Grid>
                                {values?.allocationList?.length === 0 && (
                                    <Grid item xs={6} md={2}>
                                        <Button
                                            disabled={!values?.staffId}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setIsShow(true)}
                                        >
                                            Add Allocation
                                        </Button>
                                    </Grid>
                                )}
                                {values?.allocationList?.length > 0 ? (
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Program Name</TableCell>
                                                        <TableCell>Semester</TableCell>
                                                        <TableCell>Subject</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values?.allocationList?.map((allocation, index) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {allocation?.subjectDetail?.subjectMini?.courseName || 'N/A'}
                                                            </TableCell>
                                                            <TableCell>{allocation?.sem || 'N/A'}</TableCell>
                                                            <TableCell>
                                                                {allocation?.subjectDetail?.subjectMini?.subjectName || 'N/A'}{' '}
                                                                <span style={{ fontWeight: 600 }}>
                                                                    - {allocation?.subjectDetail?.subjectMini?.subjectCode}
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {/* {renderTableContent()} */}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        {/* {values?.allocationList?.length > 0 && !values?.isLoading && (
                                            <Grid item xs={12}>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                                    component="div"
                                                    count={values?.allocationList?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        )} */}
                                    </Grid>
                                ) : (
                                    <DataNotAvailablePage
                                        text={
                                            values?.staffId
                                                ? 'No Allocation found for selected session'
                                                : 'Select Batch and Staff to see the allocation'
                                        }
                                        style={{ height: '65vh' }}
                                    />
                                )}
                                {values?.allocationList?.length > 0 && (
                                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                                        <Button
                                            disabled={!values?.staffId}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setIsShow(true)}
                                        >
                                            Update Allocation
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Formik>
        </MainCard>
    );
};

export default FacultySubjectAllocation;
