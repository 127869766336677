import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    view: null,
    add: null,
    edit: null,
    delete: null,
    menuName: null,
    isAllowed: null
};

const menuPermissionsSlice = createSlice({
    name: 'menuPermissions',
    initialState,
    reducers: {
        setPermissions(state, action) {
            const { view = false, add = false, edit = false, delete: del = false, menuName = '', isAllowed = false } = action.payload || {};
            state.view = view;
            state.add = add;
            state.edit = edit;
            state.delete = del;
            state.menuName = menuName;
            state.isAllowed = isAllowed;
        },
        resetPermissions(state) {
            state.view = null;
            state.add = null;
            state.edit = null;
            state.delete = null;
            state.menuName = null;
            state.isAllowed = null;
        }
    }
});

export default menuPermissionsSlice.reducer;

export const { setPermissions, resetPermissions } = menuPermissionsSlice.actions;
