/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';

// Department
const StudentAttendance = Loadable(lazy(() => import('views/student/WebView/Attendance/StudentAttendance')));
const StudentAttendanceReport = Loadable(lazy(() => import('views/student/WebView/Attendance/FilterReport')));
const FeedBackList = Loadable(lazy(() => import('views/student/WebView/feedBack/index')));
const StudentProfileChange = Loadable(lazy(() => import('views/student/WebView/studentProfileChange/index')));
const StudentExamFee = Loadable(lazy(() => import('views/student/WebView/ExamSetup/index')));
const StudentExamList = Loadable(lazy(() => import('views/student/WebView/ExamSetup/ExamList')));
const WebViewRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),

    children: [
        {
            path: '/webview/studentattendance/:token',
            element: <StudentAttendance />
        },
        {
            path: '/webview/student/my-attendance/report/:subjectId/:subjectName',
            element: <StudentAttendanceReport />
        },
        {
            path: '/webview/feedback/:token',
            element: <FeedBackList />
        },
        {
            path: '/webview/studentprofile/:token',
            element: <StudentProfileChange />
        },
        {
            path: '/webview/student/exam/:token',
            element: <StudentExamFee />
        },
        {
            path: '/webview/student/examlist',
            element: <StudentExamList />
        }
    ]
};

export default WebViewRoutes;
